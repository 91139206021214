<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn title="Login" color="black white--text font-weight-light" class="buttonMain" v-on="on">Login</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline green accent-2 justify-center v-card__title"
          primary-title
        >Login</v-card-title>

        <v-card-text>
          <v-form class="px-3" align="center" id="signup-form">
            <div class="text1">Email</div>
            <input v-model.trim="myemail" type="text" class="input1" />
            <div class="text1">Password</div>
            <input v-model="mypwd" :type="pwdtype" class="input1" />
            <div>
              <div class="text-xs-center">
                <div class="ma-5"></div>
                <v-btn v-on:click="submit" class="ma-5">submit</v-btn>

                <v-btn class="ma-5" text icon v-on:click="showPass">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </div>
              <v-btn color="grey" text v-on:click="forgotPassword">Forgot Password</v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div color="primary" text id="outtxt" class="red--text">{{output}}</div>
          <v-spacer></v-spacer>
        </v-card-actions>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { firebaseapp } from "@/db";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      myemail: "",
      mypwd: "",
      showPasswordBtn: null,
      pwdtype: "password",

      output: "",
      dialog: false,
    };
  },
  mounted() {
    // this.mybuttons.forEach(element => {
    //   element.show = false;
    // });
    this.$store.commit("setShowHelpButton", { b: false });
  },
  methods: {
    submit() {
      // console.log("submit ", this.email, this.mypwd, this.pwdtype);

      if (this.myemail == "" || this.mypwd == "") {
        this.setOutText("Email or password cannot be blank");
        return;
      }
      // login to firebaseappapp
      const auth = getAuth(firebaseapp);
      signInWithEmailAndPassword(auth, this.myemail, this.mypwd)
          .then((cred) => {
          console.log("loggedin cred: ", cred);
          if (cred != null) {
            this.setOutText("login success");
          } else {
            this.setOutText("login failed");
          }
          this.$emit("shownavbar", true);
       //   this.$router.push({ name:"home"});
        })
        .catch((error) => {
          this.setOutText(error);
        });

/*
      firebaseapp
        .auth()
        .signInWithEmailAndPassword(this.myemail, this.mypwd)

        .then((cred) => {
          console.log("loggedin cred: ", cred);
          if (cred != null) {
            this.setOutText("login success");
          } else {
            this.setOutText("login failed");
          }
          this.$emit("shownavbar", true);
       //   this.$router.push({ name:"home"});
        })
        .catch((error) => {
          this.setOutText(error);
        });
        */
      this.mypwd = "";
    },

    showPass() {
      console.log("show password ");
      if (this.pwdtype === "password") {
        this.pwdtype = "text";
      } else {
        this.pwdtype = "password";
      }
    },
    setOutText(txt) {
      var msg = "";
      this.output = txt;
      setTimeout(() => {
        this.output = msg;
      }, 3000);
    },
    forgotPassword() {
  //    console.log("FORGOT");
      this.$router.push("/resetpassword");
    },
  },
};
</script>

<style scoped>
.buttonMain {
  width: 10rem;
  height: 5rem;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.text1 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  /* background-color: burlywood; */
}
.input1 {
  margin: auto;
  font-size: 1.4rem;
  border-style: solid;
  border-color: rgb(169, 243, 179);
  width: 100%;
  padding: 0.8rem;
}
</style>
