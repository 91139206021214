<template>
    <router-link :to="{ name: 'home' }" class="footer1">
    <h1>{{ footertext }}</h1>
    </router-link>
</template>

<script>
export default {
    props: {
        footertext:{
            default: "Empathystone.com",
            type: String
        } 
    }
}
</script>
