<template>
  <div class="home">
    <div>
      <h1 class="ma-5">Logout Page</h1>

      <span v-if="(email == '')" class="grey lighten-5 ma-5">
        Not logged in
      </span>
<!--
      <p>User email: {{ email }}</p>
      <p>uid: {{ uid }}</p>
      -->
    </div>

    <div v-if="(email != '')">

       <span style="z-index: 4">
        <h3 class="my-h3-style" > Logged in as: {{email}}</h3>
      </span>
     
      <br/>
      <v-btn class="red accent-1" @click="logout">Logout</v-btn>
      
      <br/>
     
    </div>
  </div>
</template>

<script>
// import firebase from "@/db";

export default {
  data() {
    return { uid: "" };
  },
  props: {
    email: String,
    mybuttons: Array
  },

  methods: {
    logout() {
      //   console.log("logout");
      this.$store.commit("signOut");
      this.uid = "";

      //  firebase.auth().signOut();
    }
  },

  mounted() {
    this.uid = this.$store.getters.getUser.uid;
    var user = this.$store.getters.getUser;
    console.log(user);
    this.mybuttons.forEach(element => {
      element.show = false;
    });
    /*
    // if NOT logged in
    if (!("email" in this.user)) {
      this.menulinks.forEach(element => {
        console.log("status mounted:  ");
        if (
          element.text === "View" ||
          element.text === "Editor" ||
          element.text === "Status"
        ) {
          element.show = false;
        } else {
          element.show = true;
        }
      });
    } else {
      // user is logged in
      this.menulinks.forEach(element => {
        console.log("status mounted:  ");
        if (element.text === "Status") {
          element.show = false;
        } else {
          element.show = true;
        }
      });
      
    }
    */
    //console.log("status userEmail: = ", this.userEmail);
    // setTimeout(() => {
    //   //your code to be executed after 1 second
    //   this.getUserDetails();
    // }, 1000);
  }
};
</script>

<style scoped>
.home {
  color: 222;
  justify-content: center;
  text-align: center;
}

p {
  width: 80%;
  margin: auto;
}
.bbody {
  background: #fef;
}

.bhead {
  background: #eff;
  margin-top: 20px;
}
</style>

