<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn  color="yellow" class="pa-5 mt-5" v-on="on">Purchase</v-btn>
      </template>

      <v-card>
        <v-card-title class="headline blue-grey lighten-3 nobreak" primary-title>Introductory Offer Pricing</v-card-title>
        <v-container>
          <v-card-text>
            Once purchased, we will send you a Tag Pack, which gives the ability to jump directly to your site, using any NFC-enabled device
            (typically a phone or tablet)
          </v-card-text>

          <div style="width:12rem; margin:auto;">
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="GDVPX7G9R2M5U">
              <table>
                <tr>
                  <td align="center">
                    <input type="hidden" name="on0" value="Tag Pack" />Tag Pack
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="parent">
                      <div class="custom-select" style="width:220px;">
                        <div class="selector image1">
                          <select name="os0">
                              <option value="1 x Tag Pack">1 x Tag Pack €85.00 EUR</option>
                              <option value="2 x Tag Pack">2 x Tag Pack €160.00 EUR</option>
                              <option value="3 x Tag Pack">3 x Tag Pack €210.00 EUR</option>
                          </select>
                        </div>
                        <v-icon class="image2">arrow_drop_down</v-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="imgBorder">
                <input type="hidden" name="currency_code" value="EUR" />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                  border="0"
                  name="submit"
                  alt="PayPal - The safer, easier way to pay online!"
                />
                <img
                  alt
                  border="0"
                  src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </div>
              <!--
              <br />
              <hr />
              <hr />
              <hr />
              <hr />
              <hr />
              <br />
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="46C4MEZA976FQ" />
              <table>
                <tr>
                  <td align="center">
                    <input type="hidden" name="on0" value="Tag Pack" />Tag Pack
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="selectdiv">
                      <select name="os0">
                        <option value="1 x Tag Pack">1 x Tag Pack €150.00 EUR</option>
                        <option value="2 x Tag Pack">2 x Tag Pack €300.00 EUR</option>
                        <option value="3 x Tag Pack">3 x Tag Pack €400.00 EUR</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="imgBorder2">
                <input type="hidden" name="currency_code" value="EUR" />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                  border="0"
                  name="submit"
                  alt="PayPal - The safer, easier way to pay online!"
                />
                <img
                  alt
                  border="0"
                  src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </div>
              -->
            </form>
          </div>

          <v-card-actions>
            <v-btn color="primary" class="ma-5" text @click="dialog = false" default>Cancel</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import firebase from "@/db";
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.text1 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  /* background-color: burlywood; */
}
.input1 {
  margin: auto;
  font-size: 1.4rem;
  border-style: solid;
  border-color: rgb(169, 243, 179);
  width: 100%;
  padding: 0.8rem;
}
.nobreak{
  word-break: normal; /* maybe !important  */
}
.selector {
  background-color: #eeeeee;
  border: 1px rgb(232, 238, 151) solid;
  margin-bottom: 1rem;
  outline-color: coral;
  /*  height:2.0rem; */
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
  border: 1px rgb(190, 190, 189) solid;
  /* border: 1px rgb(238, 255, 0) solid; */
}
.image2 {
  position: absolute;
  top: 0px;
  left: 90%;
  border: 1px rgb(190, 190, 189) solid;
  width: 10%;
  background-color: #eeeeee;
}
.imgBorder {
  border: 1px rgb(219, 219, 219) solid;
  margin-left: 1rem;
  padding-top: 1rem;

  /* background-color: chocolate; */
}
.imgBorder2 {
  border: 1px rgb(219, 219, 219) solid;
  width: 10.5rem;
  margin-left: 3rem;
  padding-top: 1rem;

  /* background-color: chocolate; */
}

.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/

  min-width: 250px;
  margin: 10px 1%;
}

.selectdiv:after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  color: #bde0ec;
  right: 11px;
  top: 6px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  border-left: 1px solid #bde0ec;
  position: absolute;
  pointer-events: none;
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  display: block;
  width: 100%;
  max-width: 330px;
  height: 50px;
  float: right;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #aaddf0;
  -ms-word-break: normal;
  word-break: normal;
}
</style>
