<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <v-app class="grey lighten-4">
      <span v-if="showNavbar">
        <Navbar v-bind:menulinks="menulinks" v-bind:mybuttons="mybuttons" v-bind:email="email" />
      </span>
      <v-main>
        <div class="container">
          <router-view
            @shownavbar="setShowNavbar"
            v-bind:mybuttons="mybuttons"
            v-bind:email="email"
            v-bind:menulinks="menulinks"
          />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Navbar from "../views/Navbar";
import { firebaseapp } from "../db/index.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

//import Vue from "vue";
// 089 418 5605  kevins phone number
export default {
  components: {
    Navbar: Navbar,
  },
  data() {
    return {
      showNavbar: true,
      email: "",
      menulinks: [
        {
          icon: "home",
          text: "Home",
          route: "/home",
          show: true,
        },
        {
          icon: "wysiwyg",
          text: "View Demo Page",
          route: "/view/05d0f552",
          show: true,
        },
        {
          icon: "info",
          text: "Frequently Asked Questions",
          route: "/view/8c6572e0",
          show: true,
        },
        {
          icon: "groups",
          text: "Partners / Resellers",
          route: "/about",
          show: true,
        },
        {
          icon: "unarchive",
          text: "Purchase",
          route: "/purchase",
          show: true,
        },
        {
          icon: "account_balance",
          text: "Terms and Conditions",
          route: "/terms",
          show: true,
        },
        {
          icon: "exit_to_app",
          text: "Logout",
          route: "/status",
          show: true,
        },
        {
          icon: "mail_outline",
          text: "Contact",
          route: "/helpscreen",
          show: true,
        },
        {
          icon: "person_outline",
          text: "Sample Page",
          route: "/view/ff08f79d",
          show: true,
        },
        /*      
        {
          icon: "person_outline",
          text: "About / Logout",
          route: "/about",
          show: true
        }
        */
      ],

      mybuttons: [
        { text: "Add Block", id: 1, show: false },
        { text: "Delete Block", id: 2, show: false },
      ],
    };
  },
  methods: {
    setShowNavbar(b) {
      this.showNavbar = b;
    },
    createNewUser(uid) {
      var userRef = firebaseapp.firestore().collection("u").doc(uid);

      userRef.get().then((doc) => {
        if (!doc.exists) {
          console.log("App about to add new user");
          var timestamp = new Date();
          var obj = {
            email: this.email,
            sitecodes: [],
            datecreated: timestamp,
          };
          userRef.set(obj).then(() => {
            console.log("App NEW USER CREATED ");
            // firebase.auth().signOut();
          });
        } else {
          console.log("App user already exists ");
        }
      });
    },
  },

  mounted() {
    // this.$store.commit("loadSiteCodesFromDB");
    this.mybuttons.forEach((element) => {
      element.show = false;
    });
    this.menulinks.forEach((ml) => {
      ml.show = true;
    });

    this.$emit("shownavbar", true);
    // check if user database entry exists

    // if (firebase.auth().currentUser != null) {
    //   var uid = firebase.auth().currentUser.uid;
    //   console.log("uid === ", uid);

    //   firebase
    //     .firestore()
    //     .collection("u")
    //     .doc(uid)
    //     .get()
    //     .then((docsnapshot) => {
    //       if (docsnapshot.exists) {
    //         console.log("App uid ", uid, " exists ");
    //         console.log("docsnapshot == ", docsnapshot);
    //         //      this.createNewUser(uid);

    //         this.$store.commit("loadSiteCodesFromDB", uid);
    //       } else {
    //         console.log("App uid ", uid, " does not exist ");
    //         //      this.createNewUser(uid);
    //       }
    //     });
    // }
  },
  created() {
    //  firebaseapp.auth().onAuthStateChanged((user) => {
    /*    console.log("onAuthStateChanged  user = ", user);  */
    const auth = getAuth(firebaseapp);

    onAuthStateChanged(auth, _user => {
      this.$store.commit("clearData");
      this.$store.commit("loadUser", _user);

      if (_user === null) {
        this.email = "";
        this.sitecodes = [];
      } else {
        var auid = _user.uid.toString();
        this.email = _user.email;

        this.createNewUser(auid);

        //   console.log("App this.email === ", this.email);
        this.$store.commit("loadSiteCodesFromDB", auid);
      }
    });
  },
};
</script>

<style >
body {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Ariel, sans-serif;
  line-height: 1.6;
  background: #e8f7ff;
}

* {
  text-transform: none !important;
}

.container {
  max-width: 100%;

  overflow: none;
}
</style>
