<template>
  <div>
    <v-container fluid center class="text-center">
      <v-btn class="my-5" @click="showDialog = !showDialog">Leave a message</v-btn>

      <div class="allcomm" v-if="showDialog">
        <p>Leave an optional message</p>
        <div class="comments">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div>Message</div>
            <v-textarea
              v-model="comment"
              :rules="commRules"
              placeholder="Enter message"
              solo
              required
            ></v-textarea>

            <div>Name</div>
            <v-text-field
              v-model="commName"
              solo
              placeholder="Enter Name"
              :rules="nameRules"
              required
            ></v-text-field>

            <div>
              Your Email Address (emails sent to site-creator only and never for
              third-party use)
            </div>
            <v-text-field
              v-model="senderEmail"
              :rules="emailRules"
              placeholder="Enter E-mail"
              solo
              required
            ></v-text-field>
            <br />
            <v-flex class="text-xs-center">
              <div v-if="!messageSent" class="pb-5">
                <v-btn @click="submit()">Submit</v-btn>
              </div>
              <div v-else class="pb-5">Message Sent </div>
            </v-flex>
          </v-form>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { firebaseapp } from "../../db/index.js";
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";

export default {
  props: {
    email: String,
    sitecode: String,
    name: String
  },
  data() {
    return {
      messageSent: false,
      showDialog: false,
      comment: "",
      commName: "",
      senderEmail: "",
      valid: true,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      commRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length <= 1000) ||
          "Message must be less than 1000 characters",
      ],
    }
  },
  methods: {
    getTimeStamp() {
      var now = new Date();
      return ((now.getDate()) + '/' +
        (now.getMonth() + 1) + '/' +
        now.getFullYear() + " " +
        now.getHours() + ':' +
        ((now.getMinutes() < 10)
          ? ("0" + now.getMinutes())
          : (now.getMinutes())) + ':' +
        ((now.getSeconds() < 10)
          ? ("0" + now.getSeconds())
          : (now.getSeconds())));
    },
    submit() {
     
      if (this.$refs.form.validate()) {
        console.log("email == ", this.email, " sitecode == ", this.sitecode)
        this.messageSent = true;

        const newMessage = {
          a: false,               // allow user to see this message
          c: this.comment,        // the message
          d: false,               // will user display on page
          e: this.senderEmail,    // commentators message
          n: this.commName,       // commentators name
          new: true,              // new message
          o: this.email,          // send email to site owner
          s: this.name,           // NB deceased name
          t: this.getTimeStamp()  // date and time message left
        }

        const db = getFirestore(firebaseapp);
        const docRef = doc(db, "c", this.sitecode);
        updateDoc(docRef, {
          c: arrayUnion(newMessage)
        });
      }
    }
  }
}
</script>