<template >
 <nav>
    <v-app-bar app text class="blue-grey lighten-5">    
      
      <v-btn icon @click="sidepanel = !sidepanel" class="blue-grey lighten-5">
        <v-icon color="blue-grey darken-2">mdi-dock-left</v-icon>
      </v-btn>
      <span class="blue-grey lighten-5 ma-5" style="z-index: 6"> EmpathyStone.com</span>
      <!---
      
      <v-spacer></v-spacer>
       <div v-if="getShowHelpButton">
       
        <v-btn class="primary mx-2" @click="showHelpButtonPressed()" style="z-index: 5">Help</v-btn>
      
      </div>
      -->
      <v-spacer></v-spacer>
      <div v-if="getConfirmDialog">
       
        <v-btn class="error mx-2" @click="publish()" style="z-index: 2">SAVE</v-btn>
      
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="mx-2" style="z-index: 1">
            <span>Menu</span>
          </v-btn>
        </template>
        <v-list class="blue-grey lighten-5">
          <span>
            <v-layout class="pa-1 mb-1" column>
              <v-list-item
                v-for="(link, index) in this.menulinks"
                v-show="link.show"
                :key="link.text"
                router
                :to="link.route"
                @click="menuStuff(index)"
                style="z-index: 4"
              >
                <v-icon class="grey--text text--darken-2">{{ link.icon }}</v-icon>
                <v-list-item-title class="pl-2 grey--text text--darken-4">{{link.text}}</v-list-item-title>
              </v-list-item>
            </v-layout>
          </span>

          <span >
            <v-list-item 
              @click="menu_buttons(b.id)"
              v-for="b in this.mybuttons"
              v-show="b.show"
              :key="b.text"
            >
            <v-spacer></v-spacer>
              <v-btn class="mybtn"
              >{{b.text}} </v-btn>
               <v-spacer></v-spacer>
            </v-list-item>
          </span>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      :clipped="false"
      v-model="sidepanel"
      enable-resize-watcher
      app
      
      class="blue-grey lighten-5"
    >
      <v-list>
        <div class="mb-5"></div>
        <v-list-item
          v-for="(link, index) in this.menulinks"
          v-show="link.show"
          :key="link.text"
          router
          :to="link.route"
          @click="menuStuff(index)"
        >
          <v-list-item-action>
            <v-icon class="grey--text text--darken-2">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text text--darken-4">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="menu_buttons(b.id)"
          v-for="b in this.mybuttons"
          v-show="b.show"
          :key="b.text"
        >
          <v-btn class="mybtn">{{b.text}}</v-btn>
        </v-list-item>
      </v-list>
      
    </v-navigation-drawer>
   
  </nav>
  
</template>

<script>
export default {
  props: {
    menulinks: Array,
    mybuttons: Array,
    blocks: Array,
    email: String,
    showNavbar: Boolean
  },
  computed: {
    getConfirmDialog() {
      return this.$store.getters.getDirtyFlag;
    },
    getShowHelpButton(){
      return this.$store.getters.getShowHelpButton;
    }
    
  },
  data() {
    return {
    
      sidepanel: false,
      refreshMenu: false,
      on: false
    };
  },
  methods: {
    menuStuff(id) {
      console.log("menustuff", id);
      if (id === 2) {
        this.publish();
      }
    },
    menu_buttons(id) {
      /*
      1. add block
      2. publish
      */
      console.log("menu_items outside add block  id = " + id);

      switch (id) {
        case 1:
          console.log("menu_items insertNewBlock id = " + id);
          //  this.$store.commit("insertNewBlock", id);
          this.$store.commit("insertNewBlock", id + 1);
          break;
        case 2:
          console.log("menu_items delete block  id = " + id);
          this.$store.commit("deleteBlock", id);
          break;
   
      }
    },
    showHelpButtonPressed(){
        console.log("HELP BUTTON PRESSED");
        this.$store.commit("toggleShowEditorHelp");
    },
    publish() {
      console.log("publish");
      this.$store.commit("saveBlocksToDB");
    }
  },
   mounted() {

      //   this.sidepanel = false;
      this.menulinks.forEach(element => {
        element.show = true;
      });

      console.log("navbar mounted this.mybuttons: ", this.mybuttons);
    }
};
</script>

<style scoped>
.my-h3-style {
  color: #aaaaaa;
  padding-left: 5%;
}

.mybtn {
  color: black;
  width: 80%;
}

btn__content {
  padding: 0;
  color: red;
}

div.card__actions .btn {
  min-width: 0;
}

.navbar {
  height: 60px;
  width: 100vw;
  background: hsl(200, 50%, 50%);
  position: fixed;
  box-shadow: 0 2px 15px rgba(71, 120, 120, 0.5);
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
}

.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
  background: #33eeff;
}

/*
.save_btn {
  color: black;
  background-color: #114466;
}
*/
</style>
