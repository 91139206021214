import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)


import { firebaseapp } from '../db/index';
import { signOut, getAuth } from 'firebase/auth';
// import { getFirestore, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";


export const store = new Vuex.Store({
  state: {
    blocks: [],
    user: {},
    sitecodes: [],
    name: "",
    date: "",
    intro: "",
    allowComments: false,
    unusedCode: null,

    hideheader: false,
    hidename: false,
    hideintro: false,
    redirectPage: false,
    redirectURL: "",

    showHelpButton: false,
    showEditorHelp: false,

    x: false,

    currentSiteId: "",
    dirtyFlag: false,
    blockIndex: 0
  },
  getters: {
    getUnusedCode: (state) => {
      return state.unusedCode;
    },
    getShowEditorHelp: (state) => {
      return state.showEditorHelp;
    },
    getShowHelpButton: (state) => {
      return state.showHelpButton;
    },
    getBlockVideoId: (state) => (i) => {
      return state.blocks[i].v
    },
    getUid: state => {
      return state.user.uid
    },
    getIntro: state => {
      return state.intro
    },
    getBlockIndex: state => {
      return state.blockIndex
    },
    getName: state => {
      return state.name
    },
    getDate: state => {
      return state.date
    },
    getSiteCodes: state => {
      return state.sitecodes
    },
    getUser: state => {
      return state.user
    },
    getBlocks: state => {
      return state.blocks
    },
    getCurrentSiteId: state => {
      return state.currentSiteId;
    },
    getDirtyFlag: state => {
      return state.dirtyFlag;
    },
    getAllowComments: state => {
      return state.allowComments;
    },
    getBlocksLength: state => {
      return state.blocks.length;
    },
    getHideHeader: state => {
      return state.hideheader;
    },
    getHideName: state => {
      return state.hidename;
    },
    getHideIntro: state => {
      return state.hideintro;
    },
    getRedirectPage: state => {
      return state.redirectPage;
    },
    getRedirectURL: state => {
      return state.redirectURL;
    },
/*
    async getPreviewParts(state) {

      var n = state.name ? state.name : "";
      var d = state.date ? state.date : "";
      var i = state.intro ? state.intro : "";
  

      var a = state.allowComments ? state.allowComments : false;

      return {
        name: n,
        date: d,
        intro: i,
        allowComments: a,
       
      }
    }
    */
  },
  mutations: {
    /*
    addCharity(state, charity) {

      state.scode = charity.scode
      console.log("STATE scode = ", state.scode)

      const db = getFirestore(firebaseapp);
      const docRef = doc(db, "sc", charity.scode);
      setDoc(docRef, {
        softlink: charity.name,
        displayatbottom: charity.displayatbottom,
        displaylink: charity.displaycharitylink
      }, { merge: true });

      console.log('CHARITY = ', charity)

    }, */
    toggleAddLink(state, index) {
      if (state.blocks[index].s) {
        state.blocks[index].s = !state.blocks[index].s;
      }
      else {
        state.blocks[index].s = true;
      }
      store.hotUpdate(state.blocks); // refresh dom view
      state.dirtyFlag = true;
    },

    setRedirectURL(state, b) {
      state.redirectURL = b;
    },
    setRedirectPage(state, b) {
      state.redirectPage = b;
    },
    setHideHeader(state, b) {
      state.hideheader = b;
    },
    setHideName(state, b) {
      state.hidename = b;
    },
    setHideIntro(state, b) {
      state.hideintro = b;
    },
    setUnusedCode(state, sitecode) {
      state.unusedCode = sitecode;
    },
    setShowEditorHelp(state, obj) {
      state.showEditorHelp = obj.b;
    },
    setShowHelpButton(state, obj) {
      state.showHelpButton = obj.b;
    },
    toggleShowEditorHelp(state) {
      state.showEditorHelp = !state.showEditorHelp;
    },
    setBlockVideoId(state, obj) {
      state.blocks[obj.i].v = obj.vidcode;
    },
    addSiteCode(state, obj) {
      state.sitecodes.push(obj)
    },

    clearDirtyFlag(state) {
      state.dirtyFlag = false;
    },
    setDirtyFlag(state) {
      state.dirtyFlag = true;
    },
    setBlockIndex(state, obj) {
      state.blockIndex = obj.index;
    },
    updateDate(state, d) {
      state.date = d
    },
    updateName(state, txt) {
      state.name = txt
    },
    updateEditDate(state) {
      state.editDate = serverTimestamp()
    },
    updateNameAndDate(state, obj) {
      console.log("Store line 99 updateAndDate obj == ", obj)
      console.log("Store line 100 updateAndDate state == ", state)
      //console.log("Store line 100 updateAndDate index == " , )

      if (!obj.intro) {
        obj.intro = "";
      }

      if (!obj.displaycharitylink) {
        obj.displaycharitylink = false;
      }

      if (!obj.softlink) {
        obj.softlink = "";
      }

      if (!obj.displayatbottom) {
        obj.displayatbottom = false;
      }

      state.name = obj.name;
      state.date = obj.date;
      state.intro = obj.intro;
      state.currentSiteId = obj.scode;
      state.allowComments = obj.allowComments;
      state.displaycharitylink = obj.displaycharitylink;
      state.softlink = obj.softlink;
      state.displayatbottom = obj.displayatbottom;

      if(obj.hardlink){
        state.hardlink = obj.hardlink;
      }

      console.log("Store line 107 ");

      var uuid = state.user.uid;
      var email = state.user.email;

      if (state.currentSiteId == "preview") {
        var preObj = {
          date: state.date,
          name: state.name,
          intro: state.intro,
          email: email
        };

        firebaseapp
          .firestore()
          .collection("u/" + uuid + "/s/")
          .doc(state.currentSiteId)
          .set(preObj, { merge: true });
        console.log("Store line 122 state.blocks == ", state.blocks);

        if (!(state.blocks) || state.blocks.length == 0) {

          this.state.blockIndex = 0;
          this.commit("insertNewBlock", 0);
          console.log("Store line 123 state.blocks == ", state.blocks);
        }
        return;
      }
      console.log("Store line 245 ")


      var index = state.sitecodes.findIndex(e => {
        return e.scode == obj.scode;
      });

      if (index < 0 || index > state.sitecodes.length) {
        return;
      }

      //   console.log("Store line 150 index = ", index)

      state.sitecodes[index].name = obj.name;
      state.sitecodes[index].date = obj.date;
      state.sitecodes[index].intro = obj.intro;
      state.sitecodes[index].allowComments = obj.allowComments;
      state.sitecodes[index].email = email;
      state.sitecodes[index].displaycharitylink = obj.displaycharitylink;
      state.sitecodes[index].softlink = obj.softlink;
      state.sitecodes[index].displayatbottom = obj.displayatbottom;

      if(obj.hardlink){
        state.sitecodes[index].hardlink = obj.hardlink;
      }

      var newObj = {
        sitecodes: state.sitecodes
      }

      //      console.log("store uuid = ", uuid)
      firebaseapp.firestore()
        .collection("u")
        .doc(uuid)
        .set(newObj, { merge: true });

      //       console.log("made it here")

      var siteId = state.sitecodes[index].scode;

      var obj2 = state.sitecodes[index];

      firebaseapp
        .firestore()
        .collection("u/" + uuid + "/s/")
        .doc(siteId)
        .set(obj2, { merge: true });

    },
    clearData(state) {

      //    state.user = {};
      state.blocks = [];
      state.currentSiteId = "";
      //  state.sitecodes = [];

      //  state.unusedCode = null,

      state.blockIndex = 0;
      state.name = "";
      state.date = "";


    },
    backupImage(state, index) {
      console.log("STORE backupImage url = ", state.blocks[index].i);

      /*
      this.$http..get(state.blocks[index].i).then((response) => {

         var fileURL = window.URL.createObjectURL(new Blob([response.data]));

         var fileLink = document.createElement('a');



         fileLink.href = fileURL;

         fileLink.setAttribute('download', 'file.pdf');

         document.body.appendChild(fileLink);



         fileLink.click();

    });
     */

    },
    clearImage(state, index) {
      console.log("STORE clearImage url = ", state.blocks[index].i);



      if (index <= state.blocks.length) {
        state.blocks[index].i = "";
        state.blocks[index].v = "";
        store.hotUpdate(state.blocks);
      }

    },
    addAllSiteCodes(state, obj) {
      state.sitecodes = obj.sitecodes;
      //     console.log('store allsitecodes  = ', state.sitecodes)
    },
    movedown(state, index) {
      if (index > state.blocks.length - 2) {
        return;
      }
      var temp = state.blocks[index];
      state.blocks[index] = state.blocks[index + 1];
      state.blocks[index + 1] = temp;
      store.hotUpdate(state.blocks); // refresh dom view
    },
    moveup(state, index) {
      if (index <= 0) {
        return;
      }
      var temp = state.blocks[index];
      state.blocks[index] = state.blocks[index - 1];
      state.blocks[index - 1] = temp;
      store.hotUpdate(state.blocks); // refresh dom view
    },
    updateNames(state) {
      store.hotUpdate(state.sitecodes); // refresh dom view
    },
    moveNameDown(state, index) {
      if (index > state.sitecodes.length - 2) {
        return;
      }
      var temp = state.sitecodes[index];
      state.sitecodes[index] = state.sitecodes[index + 1];
      state.sitecodes[index + 1] = temp;
      store.hotUpdate(state.sitecodes); // refresh dom view
    },
    moveNameUp(state, index) {
      if (index <= 0) {
        return;
      }
      var temp = state.sitecodes[index];
      state.sitecodes[index] = state.sitecodes[index - 1];
      state.sitecodes[index - 1] = temp;
      store.hotUpdate(state.sitecodes); // refresh dom view
    },

    insertNewBlock(state, pos) {
      console.log("STORE", "insertNewBlock");
      //var img = "https://firebasestorage.googleapis.com/v0/b/esdata-dd385.appspot.com/o/i%2Ftag2.webp?alt=media&token=c03853fc-567b-45ed-bc19-f1bcc4fb0215"
      var img = "";
      // var pos = state.blockIndex;

      // if(pos == 0){
      //   pos = state.blocks.length;
      // }
      // pos++;


      console.log("STORE", "insertNewBlock pos === " + pos)
      var block = {
        h: "",
        b: "",
        i: img,
        v: ""
      }
      if (pos < 0) {
        pos = 0;
      }
      if (pos > (state.blocks.length + 1)) {
        pos = (state.blocks.length + 1);
      }
      state.blockIndex = pos;
      state.blocks.splice(state.blockIndex, 0, block);
      store.hotUpdate(state.blocks); // refresh dom view
    },
    deleteBlock(state) {
      //   console.log("deleteBlock I will delete block : ", state.blockIndex)
      if (state.blocks.length < 2) {
        return;
      }
      state.blocks.splice(state.blockIndex, 1);
      store.hotUpdate(state.blocks); // refresh dom view
    },
    setImagePath(state, obj) {
      //    console.log('header text',obj.text,'index',obj.index)
      state.blocks[obj.index].i = obj.url;
      store.hotUpdate(state.blocks); // refresh dom view

    },
    setLinkText(state, obj) {
      state.blocks[obj.index].t = obj.text;
    },
    setLinkUrl(state, obj) {
      state.blocks[obj.index].l = obj.text;
    },
    setHeaderText(state, obj) {
      state.blocks[obj.index].h = obj.text;
    },
    setBodyText(state, obj) {
      state.blocks[obj.index].b = obj.text;
    },
    loadUser(state, user) {
      if (user == null) {
        state.user = {};
        return;
      }
      this.commit("clearData");

      state.user.uid = user.uid;
      state.user.email = user.email;
      console.log("store loaduser uid = ", user.uid)
      this.commit("loadSiteCodesFromDB");
      console.log("store loaduser email = ", user.email)
    },
    signOut() {
      const auth = getAuth(firebaseapp);
      signOut(auth).then(() => {
        //       console.log("logged out");
        this.commit("clearData");
        this.commit("setUnusedCode", null);

      });
      /*
      firebaseapp
        .auth()
        .signOut()
        .then(() => {
          //       console.log("logged out");
          this.commit("clearData");
          this.commit("setUnusedCode", null);

        });
*/
    },
    loadBlocksFromDB(state, siteId) {

      console.log("store loadBlocksFromDB siteId = ", siteId)

      // check if user has permission
      // skip if preview site
      if (siteId != "preview") {
        var matchCode = false;
        state.sitecodes.forEach(sc => {
          if (sc.scode == siteId) {
            matchCode = true;
          }
        })

        if (matchCode == false) {
          return;
        }
      }

      state.currentSiteId = siteId;

      //var uuid = firebaseapp.auth().currentUser.uid;
      const auth = getAuth(firebaseapp)
      const uuid = auth.currentUser.uid

      firebaseapp
        .firestore()
        .collection("u/" + uuid + "/s/")
        .doc(siteId)
        .get()
        .then(doc => {
          if (doc.exists && doc.data() != null &&
            doc.data() != null) {

            state.blocks = [];
            state.name = doc.data().name;
            if (!state.name) {
              state.name = "Name Here"
            }

            state.date = doc.data().date;
            if (!state.date) {
              state.date = "Date Here"
            }

            state.intro = doc.data().intro;
            if (!state.intro) {
              state.intro = "an intro here"
            }

            state.redirectPage = doc.data().redirectPage;
            if (!state.redirectPage) {
              state.redirectPage = false;
            }

            state.redirectURL = doc.data().redirectURL;
            if (!state.redirectURL) {
              state.redirectURL = "";
            }

            // console.log("DOC HERE doc.data().redirectPage " + doc.data().redirectPage);


            var index = 0;
            doc.data().blocks.forEach(d => {

              var videoid = d.v ? d.v : "";
              var showLink = d.s ? d.s : false;
              var linkUrl = d.l ? d.l : "";
              var linkText = d.t ? d.t : "";
              state.blocks[index] = {};
              state.blocks[index].h = d.h;
              state.blocks[index].b = d.b;
              state.blocks[index].i = d.i;
              state.blocks[index].v = videoid;

              if (showLink) {
                state.blocks[index].s = showLink;
                state.blocks[index].l = linkUrl;
                state.blocks[index].t = linkText;
              }

              // state.name ? state.name : "";

              index++;
            });

            if (state.blocks == null || state.blocks == []) {
              this.state.blockIndex = 0;
              this.insertNewBlock();
            }
          } else {
            // create new site here
            console.log("No such document!");

            var scRef = firebaseapp.firestore().collection("u/" + uuid + "/s/")
              .doc(siteId);
            state.blocks = [];
            var img = ""; //"https://firebasestorage.googleapis.com/v0/b/esdata-dd385.appspot.com/o/i%2Ftag2.webp?alt=media&token=c03853fc-567b-45ed-bc19-f1bcc4fb0215"
            state.blocks.push({
              b: "",
              h: "",
              i: img,
              v: ""
            })

            state.name = "";
            state.date = ""
            state.intro = ""
            var obj = {
              blocks: state.blocks,
              name: state.name,
              date: state.date,
              intro: state.intro
            }
            scRef.set(obj, { merge: true }).then(() => {
              console.log("New site created!");
            })

          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    saveBlocksToDB(state) {
      console.log("store saveBlocksToDB called");
      if (state.currentSiteId == '') {
        console.log("store saveBlocksToDB currentSiteId is empty");
        return;
      }

      state.dirtyFlag = false;

      var obj = {
        blocks: Array,
        name: String,
        date: String,
        intro: String,
        allowComments: Boolean,
        hideheader: Boolean,
        hidename: Boolean,
        hideintro: Boolean,
        saveDate: String,
        redirectPage: Boolean,
        redirectURL: String,

      }

      // const timestamp = firestore.FieldValue.serverTimestamp();

      obj.blocks = state.blocks;
      obj.name = state.name;
      obj.date = state.date;
      obj.intro = state.intro;
      obj.allowComments = state.allowComments;
      obj.hideheader = state.hideheader;
      obj.hidename = state.hidename;
      obj.hideintro = state.hideintro;
      obj.saveDate = serverTimestamp();
      obj.redirectPage = state.redirectPage;
      obj.redirectURL = state.redirectURL;



      console.log("store saving this name : ", obj.name);

      var usedImages = [];

      state.blocks.forEach(e => {
        usedImages.push(e.i)
      })

      // var uuid = firebaseapp.auth().currentUser.uid;
      const auth = getAuth(firebaseapp)
      const uuid = auth.currentUser.uid

      var siteId = state.currentSiteId;

      var collection = "u/" + uuid + "/s";
      firebaseapp
        .firestore()
        .collection(collection)
        .doc(siteId)
        .set(obj, { merge: true })
        .then(() => {
          /*
                    // delete all .png files from firebase folder
                         var storageRef = firebase.storage().ref();
                         var storagePath = "s/" + uuid + "/" + siteId;
                         var listRef = storageRef.child(storagePath);
          
                         listRef
                              .listAll()
                              .then(res => {
                                console.log("store: in listAll storagePath = ", storagePath);
          
                                var pngImages = [];
          
                                res.items.forEach(function (itemRef) {
                                  // All the items under listRef.
                                  if (itemRef.name.includes(".png")) {
                                      pngImages.push(itemRef.name);
                                  }
                                });
          
                                for (var i = 0; i < pngImages.length; i++) {
                                  var delRef = storageRef.child(storagePath + "/" + pngImages[i]);
                                  delRef.delete();
                                }
          
                              })
          
                              
          //
          //          Delete unused images 
          //
          //           console.log("cleanupImages");          
              //      var storageRef = firebase.storage().ref();
              //      var storagePath = "s/" + uuid + "/" + siteId;
             //       var listRef = storageRef.child(storagePath);
          
                   
          
                    // listRef
                    //   .listAll()
                    //   .then(res => {
                    //     console.log("store: in listAll storagePath = ", storagePath);
          
                    //     var allImages = [];
          
                    //     res.items.forEach(function (itemRef) {
                    //       // All the items under listRef.
          
                    //       allImages.push(itemRef.name);
                    //     });
          
                    //     var storageRef = firebase.storage().ref();
                    //     var deleteImages = allImages.filter(function (val) {
                    //       console.log("save blocks val = ", val);
                    //       for (var i = 0; i < usedImages.length; i++) {
                    //         if (usedImages[i].includes(val)) {
                    //           // console.log("deleting: ", usedImages[i]);
                    //           return false;
                    //         }
                    //       }
                    //       return true;
                    //     });
          
                    //     //      const siteId = "test";
          
                    //     for (var i = 0; i < deleteImages.length; i++) {
                    //       var delRef = storageRef.child(storagePath + "/" + deleteImages[i]);
                    //       delRef.delete();
                    //     }
          
          
                    //   })
                    //   .catch(function (error) {
                    //     // Uh-oh, an error occurred!
                    //     console.log(error);
                    //   });
          
                    */
        })
        .catch(error => {
          console.error("Error writing document: ", error);
        });

      var collection2 = "u/";
      var obj2 = {};
      obj2.lastDate = serverTimestamp();
      firebaseapp
        .firestore()
        .collection(collection2).doc(uuid)
        .set(obj2, { merge: true })
        .then(() => {
          console.log("Written editTime");
        })
        .catch(error => {
          console.error("Error writing document: ", error);
        });

    },

    loadSiteCodesFromDB(state) {

      console.log("store loadSiteCodesFromDB auid = ", state.user.uid);

      var auid = state.user.uid;
      var userRef = firebaseapp.firestore()
        .collection("u")
        .doc(auid);

      state.sitecodes = [];

      userRef.get().then(docs => {

        console.log("loadSiteCodesFromDB docs = ", docs.data());



        if (docs.data() && docs.data().sitecodes) {
          state.sitecodes = docs.data().sitecodes;
        }

      });
    }
  }
})





