<template>
  <div class="mainDiv">
  
   <a :href="url" target="_blank">
      <img  :src="img" class="img" >
      <div>
      <p class="txt"> {{ txt }} </p>
      </div>
   </a>
  
   </div>
</template>

<script>
import { firebaseapp } from "../../db";
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import {  doc, getDoc, getFirestore } from 'firebase/firestore'

export default {
    data(){
        return{
            url: "",
            img: "",
            txt: ""
        }
    },
    props: {
        showlink:{
            default: "",
            type: String
        } 
    },
    methods: {
        async getLinkDocs(){

        const db = getFirestore(firebaseapp)
        
        const docRef = doc(db, "l/" + this.showlink )

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
           

      //      this.img = docSnap.data().img
            this.txt = docSnap.data().txt
            this.url = docSnap.data().url

             console.log("Document data: img = ", this.img);
            } else {
            // doc.data() will be undefined in this case
          
        }
       

        },
        async getImageUrl(){
          
            const storage = getStorage()
            const imgurl = "l/" + this.showlink + "/img1.png"

            await getDownloadURL(ref(storage, imgurl))
                .then((url) => {
                     console.log("mounted imagelink = ", url)
                        this.img = url

                         console.log("url  = ", this.img)
            }) 

         
            }
    },
    mounted(){

        if(this.showlink == "") return

        this.getImageUrl() 

        this.getLinkDocs()   
    }
}
</script>

<style scoped>
.mainDiv {
    height:120px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 100px;
   
}
.img {
    width: 50%;
    height:120px;
     max-width: 300px;
}
.txt {
    margin: 10px;
    cursor: pointer;
}

</style>