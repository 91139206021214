import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import View from '../views/ViewNormal.vue';
import Editor from '../views/Editor.vue';
import Status from '../views/Status.vue';
import ResetPassword from '../views/ResetPassword.vue';

import ViewHidden from '../views/ViewHidden.vue';
import CancelPurchase from '../views/CancelPurchase.vue';
import SucessfulPurchase from '../views/SucessfulPurchase.vue';
import Comments from '../views/Comments.vue';
import HelpScreen from '../views/HelpScreen.vue';
import BuyScreen from '../views/BuyScreen.vue';
import NoSite from '../views/NoSite.vue'
import TestPage from '@/views/NamedPages/Test.vue'
import Terms from '@/views/NamedPages/Terms.vue'

// import Signin from "@/views/SignupDialog.vue";

// import About from '../views/About.vue';

Vue.use(Router);

/*
These ones should be tidier:
https://empathystone.com/view/05d0f552     (sample)
https://empathystone.com/view/327821db   (partners)
https://empathystone.com/view/b5443070  (terms)

*/

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '/',
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/status',
      name: 'status',
      component: Status
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/view/:id',
      name: 'viewid',
      component: View
    },
    {
      path: '/partners',
      beforeEnter: (to, from, next) => {
        next({ name: 'viewid', params: { id: '327821db' } })
      }
    },
    {
      path: '/sample',
      beforeEnter: (to, from, next) => {
        next({ name: 'viewid', params: { id: 'ff08f79d' } })
      }
    },
    {
      path: '/purchase',
      name: 'BuyScreen',
      component: BuyScreen
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: CancelPurchase
    },
    {
      path: '/success',
      name: 'success',
      component: SucessfulPurchase
    },
    {
      path: '/site',
      name: 'site',
      component: ViewHidden,
      props: true
    },
    {
      path: '/editor',
      name: 'editor',
      component: Editor
    },
    {
      path: '/comments/:thisname, :siteid',
      name: 'comments',
      component: Comments,
      props: true
    },
    {
      path: '/helpscreen',
      name: 'help',
      component: HelpScreen,
      props: true
    },
    {
      path: '/about',
      beforeEnter: (to, from, next) => {
        next({ name: 'viewid', params: { id: 'ff08f79d' } })
      }
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword
    },
    {
      path: '/test',
      name: 'test',
      component: TestPage,
      props: true

    },
    {
      path: '/nosite',
      name: 'nosite',
      component: NoSite
    },
    {
      path :'*',
      component:NoSite
  }
  ]

});



export default router