 <template>
          <v-card v-if="comments.length > 0">
            <div style="text-align: center">
              <h1 class="messheader">Messages</h1>
            </div>
            <v-container class="messcontainer">
              <div v-for="(comment, index) in comments" :key="index">
                <v-card color="#FFFFFF" >
                  <div style="text-align: center">
                    <v-card-text class="elevation-0">
                      {{ comment['c'] }}
                    </v-card-text>
                  </div>
                  <v-card-text>
                    <span> {{ comment['n'] }} </span>

                    <span class="floatright"> {{ comment['t'] }} </span>
                  </v-card-text>
                </v-card>
                <br /><br />
              </div>
            </v-container>
          </v-card>
</template>

<script>

export default {
    props: { comments: Array },
  
}
</script>



<style scoped>

</style>
