<template>
  <div>
    <!--
<button class="aheader  blue-grey lighten-4" @click="gotoHome()">Home</button>
-->
    <div class="main grey lighten-5 mt-5">
      <div class="title blue-grey lighten-4">
        <h1 class="pa-5">Contact EmpathyStone</h1>
      </div>
     <div class="phone">
        <img src="@/assets/bakiklfnmdgcoanb.png"> 
     </div>
      <div v-if="emailAddress.length <= 4" class="mt-5 pa-5">
        You need to log in to contact by email
      </div>
      <div v-else>
        <div class="grey-blue lighten-1">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="ma-5">
              <div style="width: 90%; margin: auto">
                <div class="alignleft">Email</div>

                <v-text-field
                  v-model="emailAddress"
                  :rules="emailRules"
                  placeholder="Enter E-mail"
                  label="E-mail"
                  solo
                  required
                  class="txtbox"
                  :disabled="emailDisabled"
                ></v-text-field>

                <div class="alignleft">Query details</div>
                <v-textarea
                  v-model="comment"
                  :rules="commRules"
                  placeholder="Enter request here"
                  label="Comment"
                  solo
                  required
                  class="txtbox"
                  autofocus
                ></v-textarea>
                <p class="alignleft">
                  Alternatively, leave your number and we'll call you back (your number will not be kept on-file and you won't receive any spam) 
                </p>
                <v-btn
                  class="alignleft"
                  v-if="!togphonebtn && !sentdialog"
                  @click="showphone()"
                >
                  Add Phone Number</v-btn
                >

                <v-text-field
                  class="txtbox"
                  v-if="togphonebtn"
                  v-model="phonenum"
                  placeholder="Enter Phone Number"
                  label="Phone Number"
                  solo
                >
                </v-text-field>

                <p>
                  The NFC detector is in a different location on all phones. If
                  you're having difficulty scanning a tag, try rubbing all parts
                  of the back of the phone slowly around the tag. Also try the
                  top (like on a remote control). iPhone 8+ will work by
                  default. For iPhone7, you need to update your phone to the
                  latest IOS release and download an NFC app. Any Android phone
                  with NFC technology works. ...If you can use Google Pay or
                  Apple Pay (iPhone7+), this technology will also work on your
                  phone.
                </p>

                <v-flex>
                  <div class="submitButton" v-if="sentdialog == false">
                    <v-btn @click="submit()" disable="pausebtn">Submit</v-btn>
                  </div>
                </v-flex>
              </div>
              <v-alert
                v-if="sentdialog"
                color="primary"
                class="alertbox ma-5 pa-5"
              >
                Query Sent
              </v-alert>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <!-- v-else -->

    <v-layout>
      <v-row justify="center">
        <v-btn class="afooter blue-grey lighten-4" @click="gotoHome()"
          >Back</v-btn
        >
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import { firebaseapp } from "@/db";

export default {
  props: {
    menulinks: Array,
    mybuttons: Array,
  },
  data() {
    return {
      emailDisabled: false,
      pausebtn: false,
      togphonebtn: false,
      req: {},
      sentdialog: false,

      emailAddress: "",
      comment: "",
      phonenum: "",
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      commRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length <= 1000) ||
          "Message must be less than 1000 characters",
      ],
    };
  },
  computed: {
    getSiteCodes() {
      return this.$store.getters.getSiteCodes;
    },
  },
  methods: {
    showphone() {
      this.togphonebtn = !this.togphonebtn;
    },
    gotoHome() {
      this.$emit("shownavbar", true);
      this.$router.push("/home");
    },
    submit() {
      console.log("submit pressed this.pausebtn == " + this.pausebtn);
      if (this.pausebtn == true) {
        return;
      }
      if (this.$refs.form.validate()) {
        console.log("sending now...");
        

        this.pausebtn = true;

        var phonenumber = this.phonenum;
        if (this.togphonebtn == false) {
          phonenumber = "";
        }
        var text = this.comment;

        // <div style="white-space: pre-line">Some test
        // with linebreaks</div> ()
        text = text.replace(/(?:\r\n|\r|\n)/g, "<br>");

        //   var sitecodes = this.getSiteCodes;
        var sitecode = this.getSiteCodes;
         const req = {
          email: this.emailAddress,
          phone: phonenumber,
          message: text,
          sitecode: sitecode,
        };

        console.log("HelpScreen req = ", req)

        setTimeout(() => (this.pausebtn = false), 100);

        var EmailMessage = firebaseapp.functions().httpsCallable("sendHelpMail");
        

        EmailMessage( req )
          .then((result) => {
            console.log("result = ", result);
            this.sentdialog = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.$store.commit("setShowHelpButton", { b: false });

    var user = this.$store.getters.getUser;
    if (user && user.email) {
      this.emailAddress = user.email;
    } else {
      this.emailAddress = "";
    }

    this.mybuttons.forEach((element) => {
      element.show = false;
    });

    this.menulinks.forEach((ml) => {
      ml.show = true;
    });
  },
};
</script>

<style scoped>
.phone {
  margin:30px auto;
}
p {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.title {
  padding-top: 1%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.aheader {
  width: 100%;
  padding: 1em;
  border-radius: 10px;
}
.afooter {
  min-width: 20%;

  margin-top: 5%;
  padding: 1em;
  border-radius: 10px;
}
.alignleft {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-left: 5%;
}
.alignright {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-left: 5%;
}

/*
.my-text-style  >>> .v-input__slot input {
  caret-color: auto;
}

  input {
  caret-color: red !important;
}
*/

.main {
  justify-content: center;
  text-align: center;
  border-radius: 20px;
}

.about {
  margin-top: 1%;
  /* color: #ff2211; */

  border-radius: 20px;
  justify-content: center;
  text-align: center;
}

.txtbox {
  /* margin:auto;
  width:96%; */
  transform: scale(0.875);
  transform-origin: center;
}

.submitButton {
  border-radius: 10px;
  padding: 1rem;
  justify-content: right;
  text-align: right;
}

.alertbox {
  /*  width:50%;
  margin:auto;
  */

  transform: scale(0.875);
  transform-origin: center;
}
</style>