<template>
  
      <div>
        <button
          v-if="header.hideheader == false"
          class="aheader blue-grey lighten-4"
          @click="gotoHome()"
        >
          {{ header.headerText }}
        </button>

       
          <h1 v-if="header.hidename == false" class="display-2 text-center mb-2 mt-5">
            {{ header.name }}
          </h1>

          <v-container fluid center class="text-center">
            <v-layout>
              <v-flex xs12>
                <p v-if="header.hideintro == false" class="intro">{{ header.intro }}</p>
              </v-flex>
            </v-layout>
        </v-container>
      </div>
  
</template>

<script>

export default {
    props: { header: Object },
    methods: {
        gotoHome() {
            this.$emit("shownavbar", true);
            this.$router.push("/home");
        }
    }
 
}
</script>
