<template>
  <v-container class="home">
    <!--     <button class="aheader blue-grey lighten-4" @click="gotoHome()">Home</button>
         
            <div>
                 <img src="@/img/tag7.jpg" 
            alt="Empathystone.com"  
            class="titlelogo1 ma-1"   />
            </div> 
           -->
    <div v-if="hascomments" >
      <v-flex
        :class="{
          'ma-0': $vuetify.breakpoint.smAndDown,
          'ma-5': $vuetify.breakpoint.mdAndUp,
        }"
        xs12
        md10
        ma-auto
      >
        <div>
          <v-layout row wrap>
            <v-flex >
              <span class="mainname ma-5 pa-5">{{ this.thisname }} </span> 
                <v-btn class="tophomebtn blue-grey lighten-5" @click="gotoHome()">Home</v-btn>
            </v-flex>

        
          </v-layout>
        </div>

        <!--      
      <v-layout row wrap>
        <v-flex xs12 v-for="(comm, idx) in getComments" :key="idx">
          <v-card flat class="text-xs-center ma-3">
             <v-card-text>
              <div class="subheading">{{ comm.c }}</div>
              <div class="grey--text">{{ comm.e }}</div>
            </v-card-text>
              <v-card-actions class="">
              <v-btn  color="grey">
                <v-icon small left>message</v-icon>
                <span class="">Message</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout> -->

        <div v-for="(comment, i) in getComments" :key="i">
          <v-card @click="newClick(i)" >
            
              <div v-if="comment.new" class="titlebar1 my-2 py-2" ref="smhead">
                <span v-if="showdelete" class="delbtn" @click="deleteMe(i)" >X</span>
                <span>{{ comment.n }} </span>
                <span class="keepright">
                  Display on Site
                  <input
                    type="checkbox"
                    :checked="comment.d"
                    @click="checkallow($event, i)"
                  />
                </span>
              </div>
              <div v-else class="titlebar2 my-5 pb-5" ref="smhead">
                <span v-if="showdelete" class="delbtn" @click="deleteMe(i)">
                  <v-img
                    class="deletebtn"
                    :src="require('@/img/removeimage2.png')"
                  >
                  </v-img>
                </span>

                {{ comment.n }}
                <span class="keepright">
                  Display on Site
                  <input
                    type="checkbox"
                    :checked="comment.d"
                    @click="checkallow($event, i)"
                  />
                </span>
              </div>
            

            <!-- <v-card-title primary-title> -->
              <div class=" ma-5 pa-5">{{ comment.c }}</div>
            <!-- </v-card-title> -->

            <div class="bottombar">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-row class="ma-1 pa-1" justify="space-around">
                    <span> {{ comment.e }} </span>
                    <span>{{ comment.t }} </span>
                  </v-row>
                 
                </v-flex>
              </v-layout>
            </div>
            
        </v-card>
           <div class="ma-5 pa-3">  </div>
        </div>
      </v-flex>
    </div>

    <div v-else>
      <h1>No Messages</h1>
    </div>
    <v-layout  >
            <button v-if="!showdelete"
             class="enabledelete"
              @click="setDelete(true)"
              >Enable Delete</button>
            <button v-if="showdelete"
             class="enabledelete"
              @click="setDelete(false)"
              >Disable Delete</button>
    </v-layout>
    <v-layout>        
      <v-row justify="center">
        <v-btn class="afooter blue-grey lighten-3" @click="gotoHome()">Home</v-btn>
      </v-row>
    </v-layout>
  </v-container>
</template>


<script>
import { firebaseapp } from "../db";
import {  getFirestore, doc, updateDoc } from "firebase/firestore";

export default {
  props: ["siteid", "thisname"],
  data() {
    return {
      /*  siteid: 'f2796ae2' */
      comms: [],
      showdelete: false,
      hascomments: false,
    };
  },
  computed: {
    getComments() {
      return this.comms;
    },
  },
  methods: {
    newClick(i){
      // set false if true
      if(this.comms[i].new){
          this.comms[i].new = false

          this.comms.sort((a,b) => (a.t > b.t) ? 1 : -1 )

          const db = getFirestore(firebaseapp);
          const docRef = doc(db, "c", this.siteid);
          updateDoc(docRef, {
              c: this.comms
          });
      }
    },
    deleteMe(index) {
      console.log("delete comment number : ", index);
      console.log("delete comment : ", this.comms[index]);

      var sure = confirm(
        "Are you sure?\n You want to delete " +
          this.comms[index].n +
          " comment?"
      );
      if (sure) {
        var commentsRef = firebaseapp.firestore().collection("c").doc(this.siteid);

        var spliced = this.comms.splice(index, 1);

        console.log("spliced == ", spliced);

        // update firestore
        commentsRef.set({
          c: this.comms,
        });
      }
    },
    setDelete(b) {
      this.showdelete = b;
    },
    checkallow(e, index) {
      this.comms[index].d = e.target.checked;

      this.allowComments();
    },
    allowComments() {
      // console.log("this.comms == ", this.comms);

      var commentsRef = firebaseapp.firestore().collection("c").doc(this.siteid);

      commentsRef.get().then((doc) => {
        if (doc.exists && doc.data() != null) {
          if (doc.data().c != null) {
            console.log(
              "Comments BEFORE allowComments doc found:",
              doc.data().c
            );
            var ids = this.comms;
            var local_doc = doc.data().c;
            // update a (allowables) and d (deletables)
            var i = 0;
            var j = 0;
            for (i = 0; i < ids.length; i++) {
              for (j = 0; j < local_doc.length; j++) {
                console.log("MATCH outside");
                local_doc[j].new = false;
                if (local_doc[j].t == ids[i].t && local_doc[j].n == ids[i].n) {
                  local_doc[j].d = ids[i].d;
                  //                      local_doc[j].d = ids[i].d;
                  console.log("MATCH inside");
                }
              }
            }

            console.log(
              "Comments AFTER allowComments doc found:",
              doc.data().c
            );
            // update firestore
            commentsRef.set({
              c: local_doc,
            });
          } else {
            console.log("Comments allowComments doc Not found:");
          }
        }
      });
    },
    gotoHome() {
      this.$emit("shownavbar", true);
      this.$router.push("/home");
    },
    loadComments() {
      this.comms = [];
      console.log("Comments LOADCOMMENTS called:");
      firebaseapp
        .firestore()
        .collection("c")
        .doc(this.siteid)
        .get()
        .then((doc) => {
          if (doc.exists && doc.data() != null) {
            console.log("doc found:", doc.data().c);

            //   this.comms = doc.data().c;
            var tmp = doc.data().c;

            // a == allow
            this.comms = tmp.filter((el) => el.a == true);

            if (this.comms && this.comms.length > 0) {
              this.hascomments = true;
            }

           // this.comms = this.comms.reverse();
            this.comms.sort((a,b) => (a.t < b.t) ? 1 : -1 )

            // if(tmp != null){
            //     tmp.forEach(el => {
            //        this.comms = el.filter(el => el.a == true);
            //     })
            // }
          } else {
            console.log("Error not found:");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      //    console.log("arr == ",arr);
    },
  },

  mounted() {
    this.loadComments();
    console.log("thisname == ", this.thisname);
  },
};
</script>

<style scoped>
div {
  white-space: pre-wrap;
  word-break: keep-all;
}

.deletebtn {
  margin-top: 0.25rem;
  width: 1rem;
}
.enabledelete {
  font-size: 0.7rem;
  margin-left: auto;
  margin-right: 10%;
  background: rgb(175, 175, 151);
  padding: 10px;
  border-radius: 10px;
}
.tophomebtn {
  float: right;

 
}
.delbtn {
  color: #ff0000;
  float: left;
  margin-left: 1rem;
  cursor: pointer;
}

.titlelogo1 {
  width: 55%;
}

.keepright {
  /* margin-right:2rem; */
   margin-left: 30%;
  font-size: 0.8em;
}

.titlebar1 {
  /* width: 100%; */
  background-color: #aaffaa;
  /* height: 39px; */
}
.titlebar2 {
  width: 100%;
  background-color: rgb(229, 253, 229);
}
.bottombar {
  width: 100%;
  background-color: rgb(236, 250, 224);
}
.cbox {
  white-space: nowrap;
  justify-content: right;
  margin-right: 4rem;
  margin-left: 30rem;
}
.mainname {
  font-size: 2rem;
}

/*
.v-btn--active .v-btn__content { 
  color: red ;
  height: 100px;
  margin-left: 10rem;;
}  
*/
.timestamp {
  margin-left: 5rem;
}
.email {
  margin-left: 1rem;
  float: left;
}

.aheader {
  width: 70%;

  height: 50px;
  margin-bottom: 5%;
  padding: 0.3em;
  border-radius: 5px;
}

.home {
  color: f22;
  justify-content: center;
  text-align: center;
}

.titlePic {
  width: 90%;
  margin-top: 30px;
  margin-bottom: 50px;
}

.aheader {
  width: 100%;

  margin-bottom: 5%;
  padding: 1em;
  border-radius: 10px;
}
.afooter {
  min-width: 20%;

  margin-top: 5%;
  padding: 1em;
  border-radius: 10px;
}
</style>
