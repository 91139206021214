<template>
  <v-container style="overflow: hidden" class="view">
    <v-flex :class="{
      'ma-0': $vuetify.breakpoint.smAndDown,
      'ma-5': $vuetify.breakpoint.mdAndUp,
    }" xs12 md10 mx-auto>
      <div>
        <button v-if="hideheader == false" class="aheader blue-grey lighten-4" @click="gotoHome()">
          {{ this.headerText }}
        </button>

        <div v-if="hardLink.length > 3 && !displayAtBottom">
          <DisplayMainLink :showlink="hardLink" />
        </div>

        <div v-if="hardLink == '' && softLink.length > 3 && !displayAtBottom">
          <DisplayMainLink :showlink="softLink" />
        </div>

        <div v-if="this.vblocks.length > 0">
          <h1 v-if="hidename == false" class="display-2 text-center mb-2 mt-5">
            {{ name }}
          </h1>

          <v-container fluid center class="text-center">
            <v-layout>
              <v-flex xs12>
                <p v-if="hideintro == false" class="intro">{{ intro }}</p>
              </v-flex>
            </v-layout>




            <div v-for="(vblock, index) in this.vblocks" :key="index">
              <h1 class="mt-5">{{ vblock.h }}</h1>

              <div v-if="vblock.s" class="red lighten-5 pa-2">
                <p>{{ vblock.t }}</p>
                <div>
                  <a href="#" @click="gotoLink(vblock.l)"> {{ vblock.l }} </a>
                </div>
              </div>


              <div v-if="vblock.v && vblock.v.length > 2">
                <v-layout class="justify-center">
                  <v-flex xs12 md9>
                    <div class="resp-container">
                      <iframe id="ytplayer" type="text/html" class="resp-iframe" :src="getSrc(vblock.v)"
                        frameborder="0"></iframe>
                    </div>
                  </v-flex>
                </v-layout>
              </div>

              <div v-else>
                <v-layout class="justify-center">
                  <v-flex xs12 md9>
                    <v-img v-if="vblock.i != ''" :src="vblock.i" />
                  </v-flex>
                </v-layout>
              </div>
              <p class="bbody">{{ vblock.b }}</p>
            </div>
          </v-container>


          <div v-if="hardLink.length > 3 && displayAtBottom">
            <DisplayMainLink :showlink="hardLink" />
          </div>

          <div v-if="hardLink == '' && softLink.length > 3 && displayAtBottom">
            <DisplayMainLink :showlink="softLink" />
          </div>

          <br>
          <br>

          <v-card v-if="gotmessages">
            <div style="text-align: center">
              <h1 class="messheader">Messages</h1>
            </div>
            <v-container class="messcontainer">
              <div v-for="(comment, index) in this.comms" :key="index">
                <v-card color="#FFFFFF" style="width: 100%">
                  <div style="text-align: center">
                    <v-card-text>
                      {{ comment.c }}
                    </v-card-text>
                  </div>
                  <v-card-text>
                    <span> {{ comment.n }} </span>

                    <span class="floatright"> {{ comment.t }} </span>
                  </v-card-text>
                </v-card>
                <br /><br />
              </div>
            </v-container>
          </v-card>

          <div v-if="allowComments">
            <v-layout row wrap mt-5>
              <v-flex xs12>
                <v-row justify="space-around">
                  <v-btn v-if="leaveComment == false" @click="toggleButton()" class="black--text pa-5 ma-5">Leave a
                    Message</v-btn>
                  <v-btn v-else @click="toggleButton()" class="black--text">Close</v-btn>
                </v-row>
              </v-flex>
            </v-layout>
            <div v-if="leaveComment == true" class="allcomm">
              <p class="myheader1">Leave an optional message</p>
              <div class="comments">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <div>Message</div>
                  <v-textarea v-model="comment" :rules="commRules" placeholder="Enter comment" label="Comment" solo
                    required></v-textarea>

                  <div>Name</div>
                  <v-text-field v-model="commName" solo label="Name" placeholder="Enter Name" :rules="nameRules"
                    required></v-text-field>

                  <div>
                    Email (emails sent to site-creator only and never for
                    third-party use)
                  </div>
                  <v-text-field v-model="commEmail" :rules="emailRules" placeholder="Enter E-mail" label="E-mail" solo
                    required></v-text-field>
                  <br />
                  <v-flex class="text-xs-center">
                    <div v-if="dissubbtn" class="submitButton ">
                      <v-btn @click="submit()">Submit</v-btn>
                    </div>
                    <div v-else>
                      <v-flex class="mt-5">
                        <v-row class="justify-center">
                          <v-alert border="top" color="red lighten-2" dark prominent>
                            Message Sent
                          </v-alert>
                        </v-row>
                      </v-flex>
                    </div>
                  </v-flex>
                </v-form>
              </div>
            </div>

          </div>
          <button class="afooter blue-grey lighten-4" @click="gotoHome()">
            {{ this.headerText }}
          </button>
        </div>
      </div>
    </v-flex>
  </v-container>
</template>

<script>
import { firebaseapp, db } from "@/db";
import DisplayMainLink from "./Display/DisplayMainLink.vue";
import { doc, setDoc, collection, getDoc, query, where, getDocs } from "firebase/firestore";

export default {
  components: { DisplayMainLink },
  data() {
    return {
      gotmessages: false,
      dissubbtn: true,
      comms: Array,
      vblocks: Array,
      name: String,
      date: String,
      intro: String,
      allowComments: Boolean,
      hardLink: String,
      softLink: String,
      displayAtBottom: false,
      displayCharityLink: false,
      leaveComment: false,
      allowVideo: Boolean,
      commName: String,
      valid: true,
      email: String,
      commEmail: String,
      comment: String,
      sitetype: String,
      headerText: String,
      hideheader: false,
      hidename: false,
      hideintro: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      commRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length <= 1000) ||
          "Message must be less than 1000 characters",
      ],
    };
  },
  props: {
    menulinks: Array,
    mybuttons: Array,
    sitecode: String,
  },

  methods: {
    getSrc(vid) {
      return (
        "https://www.youtube.com/embed/" +
        vid +
        "?autoplay=0&origin=http://empathystone.com"
      );
    },
    playing() {
      console.log("\\o/ we are playing video!!!");
    },
    toggleButton() {
      this.leaveComment = !this.leaveComment;
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      console.log("called SUBMIT validate true siteID == ", this.sitecode);
      this.dissubbtn = false;

      var currentdate = new Date();
      var datetime =
        "" +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " - " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes(); // + ":" ;

      var o = {
        n: this.commName,
        e: this.commEmail,
        c: this.comment,
        t: datetime,
        a: true, // allow
        d: false, // display
        o: this.email, // owner email
        s: this.name,
        new: true,
      };

      var req = {
        name: this.name,
        email: this.commEmail,
        toaddr: this.email,
        message: this.comment,
        sitecode: this.sitecode,
        messageName: this.commName
      }

      var EmailMessage2 = firebaseapp.functions().httpsCallable("updateMessages");

      EmailMessage2(req)
        .then((result) => {
          console.log("result = ", result);
          //  this.sentdialog = true;
        })
        .catch(function (error) {
          console.log(error);
        });

      // const esRef = firebaseapp.firestore().collection("c").doc(this.sitecode);

      const esRef = collection(db, "c")


      // //  this.sitecode = "9c7cbea8";
      //   const docRef = firebaseapp.firestore().collection("c").doc(this.sitecode);



      // esRef.get().then((data) => { // getting the document from Firestore
      getDoc(doc(esRef, this.sitecode)).then((data) => {
        // {} is a fallback for the case if the "obj" is not present in the firestore
        var obj = data.get("c") ? data.get("c") : [];

        obj.push(o)

        console.log(" obj ===== ", obj)

        console.log("req equals ", req)


        setDoc(doc(db, "c", this.sitecode), {
          c: obj
        });










      }).catch((error) => console.log("ERROR in SendMessage" + error));

      // var EmailMessage = firebaseapp.functions().httpsCallable("updateMessages");
      // EmailMessage(req)
      //   .then((ans) => {
      //     console.log("EmailMessage return = ", ans);
      //   })
      //   .catch(function (error) {
      //     console.log("EmailMessage error = ", error);
      //   });







      return;
    },
    // submitolden() {
    //   if (this.$refs.form.validate()) {
    //     console.log("SUBMIT validate true siteID == ", this.sitecode);

    //     //   this.leaveComment = false;

    //     this.dissubbtn = false;

    //     const docRef = firebaseapp.firestore().collection("c").doc(this.sitecode);

    //     firebaseapp.firestore().runTransaction((t) => {
    //       // db is the firestore instance
    //       return t
    //         .get(docRef)
    //         .then((doc) => {
    //           // getting the document from Firestore
    //           // [] is a fallback for the case if the "obj" is not present in the firestore
    //           const obj = doc.get("c") ? doc.get("c") : [];

    //           var currentdate = new Date();
    //           var datetime =
    //             "" +
    //             currentdate.getDate() +
    //             "/" +
    //             (currentdate.getMonth() + 1) +
    //             "/" +
    //             currentdate.getFullYear() +
    //             " - " +
    //             currentdate.getHours() +
    //             ":" +
    //             currentdate.getMinutes(); // + ":" ;
    //           //     + currentdate.getSeconds();

    //           console.log("BEFORE obj = ", obj);

    //           var o = {
    //             n: this.commName,
    //             e: this.commEmail,
    //             c: this.comment,
    //             t: datetime,
    //             a: false, // allow
    //             d: false, // display
    //             o: this.email, // owner email
    //             s: this.name,
    //             new: true,
    //           };
    //           obj.push(o);

    //           console.log("o == ", o)

    //           t.set(
    //             docRef,
    //             { c: obj },
    //             {
    //               // updating the value to Firestore.
    //               merge: true,
    //             }
    //           );



    //           //        EmailMessage(req)
    //           //          .then((result) => {
    //           //           console.log("result = ", result);
    //           //            this.sentdialog = true;
    //           //       console.log("map updated", result);
    //           //       this.$refs.form.reset();
    //           //          })
    //           //          .catch(function (error) {
    //           //            console.log(error);
    //           //          });

    //           // //      EmailMessage(req).then(result => {
    //           // //       // Read result of the Cloud Function.
    //           // //   //    var sanitizedMessage = result.message;
    //           // //       console.log("updateMessages return:>> ", result);
    //           // //       // ...
    //           // //     })
    //           // //     .then((result) => {
    //           // //       console.log("map updated", result);
    //           // //       this.$refs.form.reset();
    //           // //     })
    //           // //     .catch((error) => this.handleError(error));
    //           // // });
    //           return datetime;
    //         })

    //     }).then((datetime) => {


    //       var req = {
    //         name: this.commName,
    //         email: this.commEmail,
    //         phone: datetime,
    //         message: this.comment
    //       }
    //       var EmailMessage = firebaseapp.functions().httpsCallable("updateMessages");
    //       console.log("here be req = ", req);

    //       EmailMessage( req )
    //         .then((result) => {
    //           console.log("result = ", result);
    //         })

    //     }).catch(function (error) {
    //       console.log(error);


    //      });
    //   }
    // },
    gotoHome() {
      if (this.sitetype == "trails") {
        this.$emit("shownavbar", true);
        this.$router.push("/home");
      } else {
        this.$emit("shownavbar", true);
        this.$router.push("/home");
      }
    },
    gotoLink(link) {
      if (link || link.length > 5) {
        //window.location.replace( 'http://www.google.com' );
        window.open("http://" + link, "_blank");
      }
    },
    showSite(siteid, uuid) {


      var pathstr = "u/" + uuid + "/s/";
      //  var ref = firebaseapp.firestore().collection(pathstr).doc(siteid);
      var ref = collection(db, pathstr);

      // ref.get().then((doc) => {

      getDoc(doc(ref, siteid)).then((doc) => {

        this.vblocks = [];

        console.log("ViewHidden doc.data() =  ", doc.data());

        if (doc.data()) {
          let redirectpage = doc.data().redirectPage;

          console.log("ViewHidden redirectpage =  ", redirectpage);

          let gotoURL = doc.data().redirectURL;
          if (redirectpage == true && (gotoURL || gotoURL.length > 5)) {
            //window.location.replace( 'http://www.google.com' );
            window.location.replace("http://" + gotoURL);
          } else {
            this.name = doc.data().name;
            this.date = doc.data().date;
            this.intro = doc.data().intro;
            this.allowComments = doc.data().allowComments
              ? doc.data().allowComments
              : false;
            this.allowVideo = doc.data().allowVideo
              ? doc.data().allowVideo
              : false;
            //       this.videoId = doc.data().videoId ? doc.data().videoId : "";
            this.email = doc.data().email ? doc.data().email : "";

            this.softLink = doc.data().softlink ? doc.data().softlink : "";
            this.displayAtBottom = doc.data().displayatbottom ? doc.data().displayatbottom : false;
            this.displayCharityLink = doc.data().displaycharitylink ? doc.data().displaycharitylink : false;

            if (this.displayCharityLink == false) this.softLink = ""

            this.sitetype = "";
            // this.sitetype = doc.data().sitetype ? doc.data().sitetype : "";
            if (doc.data().sitetype) {
              this.sitetype = doc.data().sitetype;
            }

            if (this.sitetype == "trails") {
              this.headerText = "TapTheStone.com";
            } else {
              this.headerText = "EmpathyStone.com";
            }

            // this.sitetype = "dummydum";
            console.log("HERE this.sitetype == ", this.sitetype);
            console.log("HERE doc.data().sitetype == ", doc.data().sitetype);

            this.hideheader = false;
            this.hidename = false;
            this.hideintro = false;

            if (doc.data().hideheader) {
              this.hideheader = doc.data().hideheader;
            }
            if (doc.data().hidename) {
              this.hidename = doc.data().hidename;
            }
            if (doc.data().hideintro) {
              this.hideintro = doc.data().hideintro;
            }

            var index = 0;
            doc.data().blocks.forEach((d) => {
              //  console.log("fetching in view: ", d);
              //  this.blocks.push(d, { id: d.id });
              this.vblocks[index] = {};
              this.vblocks[index].h = d.h;
              this.vblocks[index].b = d.b;
              this.vblocks[index].i = d.i;
              this.vblocks[index].v = d.v;
              this.vblocks[index].s = d.s;
              this.vblocks[index].l = d.l;
              this.vblocks[index].t = d.t;

              index++;
            });
          }
          //  window.history.pushState({ page: 1 }, "title 1", "?page=1");

          console.log("softlink = ", this.softLink)
          console.log("displayCharityLink = ", this.displayCharityLink)
          console.log("displayAtBottom = ", this.displayAtBottom)
        }
      });
    },
    async getSiteFromDB() {
      this.vblocks = [];
      this.name = "";
      this.email = "";
      this.allowComments = false;
      this.hardLink = "";
      this.softLink = "";
      this.displayAtBottom = false;
      this.displayCharityLink = false;
      this.allowVideo = false;
      this.sitetype = "";
      this.headerText = "";

      console.log("viewHidden getSiteFromDB  sitecode == ", this.sitecode);
      if (this.sitecode == null || this.sitecode == "") {
        // return;
        this.$router.push({ name: "nosite" });
      }

      // if (this.sitecode == "") {
      //   this.$emit("shownavbar", true);
      //   this.$router.push("/home");
      // }

      var siteid = this.sitecode;

      // var ref = firebaseapp
      //   .firestore()
      //   .collection("sc")
      //   .where("scode", "==", siteid);

      var ref = collection(db, "sc")

      // ref
      //   .get()
      const q = query(ref, where("scode", "==", siteid))

      const querySnapshot = getDocs(q)
      querySnapshot
        .then((doc) => {
          if (!doc.empty) {
            var uid = doc.docs[0].data().used;

            this.hardLink = doc.docs[0].data().hardlink;

            if (!this.hardLink) this.hardLink = ""

            //         console.log("add hardlink    = ", this.hardLink );


            if (uid) {
              this.showSite(siteid, uid);
            } else {
              console.log("site not defined YET");
              //    this.$router.push("/NotFound");
              // this.$router.push({ name: "/home", params: { sitecode: siteid } });
              this.$store.commit("setUnusedCode", siteid);
              this.$emit("shownavbar", true);
              this.$router.push("/home");
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("View : No such document!");
            //  this.$router.push("/NotFound");
            this.$emit("shownavbar", true);
            this.$router.push("/home");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      //  var commentsRef = firebaseapp.firestore().collection("c").doc(siteid);
      var commentsRef = collection(db, "c")

      getDoc(doc(commentsRef, siteid)).then((doc) => {

        //   commentsRef.get().then((doc) => {
        if (doc && doc.data()) {
          console.log("comment here == ", doc.data());
          this.comms = doc.data().c.filter((el) => {
            if (el.d == true) {
              return el;
            }
          });
          //    console.log("getSiteFromDB() this.comms == ", this.comms);

          //    console.log("beforeMount this.comms.length === ", this.comms.length);
          if (this.comms.length > 0) {
            this.gotmessages = true;
          }
        }
      });
    },
  },

  beforeMount() {
    //this.myEmailMessage = firebaseapp.functions().httpsCallable('emailMessage');

    this.commName = "";
    this.commEmail = "";
    this.comment = "";

    this.getSiteFromDB();
  },
  mounted() {
    // var siteid = this.name;

    this.$emit("shownavbar", false);

    this.mybuttons.forEach((element) => {
      element.show = false;
    });
  },
};
</script>

<style scoped>
div {
  white-space: pre-wrap;
}

.view {
  width: 100%;
}

.floatright {
  float: right;
  margin-right: 2rem;
}

.messheader {
  background-color: #f0f1f1;
}

.messcontainer {
  color: black;
  background-color: #fefefe;
  width: 100%;
}

/*
.videoblock{
  margin:auto;
 
}
*/
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.allcomm {
  background-color: #ecfcfc;
}

.myheader1 {
  margin-left: 5%;
  margin-top: 1rem;
  /* background-color:#ddeeff; */
}

.comments {
  margin: auto;
  width: 80%;
}

.aheader {
  width: 100%;

  margin-bottom: 2%;
  padding: 1em;
  border-radius: 10px;
}

.afooter {
  width: 100%;

  margin-top: 40px;
  padding: 1em;
  border-radius: 10px;
}

.submitButton {
  margin-left: 70%;
  padding: 2em;
  border-radius: 10px;
}

.intro {
  font-size: 1.5em;
  text-align-last: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.imgclassSm {
  width: 15%;
  margin: auto;
}

.imgclassMed {
  width: 45%;
  margin: auto;
}

.imgclassLg {
  width: 75%;
  margin: auto;
}

.home {
  color: 222;
  justify-content: center;
  text-align: center;
}

.bbody {
  /* background-color: #1efefe;  */

  width: 80%;

  margin: auto;

  margin-top: 3%;
  margin-bottom: 12%;
}
</style>



