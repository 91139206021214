import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/compat/functions'
import { getFirestore } from "firebase/firestore";



const config = {
  apiKey: "AIzaSyCGOMs9Ppj9q_1SIxOovCe7JoF3yxBsG94",
  authDomain: "esdata-dd385.firebaseapp.com",
  databaseURL: "https://esdata-dd385.firebaseio.com",
  projectId: "esdata-dd385",
  storageBucket: "esdata-dd385.appspot.com",
  messagingSenderId: "298251574176",
  appId: "1:298251574176:web:6c993bafe8b6e8d1"
};

const firebaseapp = firebase.initializeApp(config);
// const timestamp = firebase.firestore.FieldValue.serverTimestamp
 const db = getFirestore(firebaseapp);





export { firebaseapp , db}


