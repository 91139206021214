<template>
  <DisplayPage :uuid="uuid" :sitecode="sitecode" />
</template>

<script>
import DisplayPage from '@/views/Display/DisplayPage.vue'
export default {
    name: 'Sample',
    components: { DisplayPage },
    data(){
        return {
            uuid: 'GevqijHBCvTMyWB2jWDBHoP2qZH2',
            sitecode: 'b5443070'
        }
    }
}
</script>

<style>

</style>