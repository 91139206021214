import Vue from "vue"
import App from "./views/App.vue"
import router from "./router/router.js"
import cors from 'cors'

import { store } from '@/store/store'


import VideoBackground from 'vue-responsive-video-background-player'

Vue.component('video-background', VideoBackground);

// styles
// import './assets/main.css'

import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

Vue.use(cors)

new Vue({
  el: '#app',
  cors,
  store,
  router,
  vuetify,

  render: (h) => h(App)
});


