
<!--
<template>
  <div class="text-xs-center">
    <v-container>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on }">
          <div>
            <v-btn color="blue-grey lighten-3" v-on="on" title="Enter code to create site">
              <span style="white-space: normal;">Purchase</span>
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title
            :key="ok"
            v-if="getUnusedCode"
            class="headline light-green accent-4"
            primary-title
          >Enter Confirmation Code</v-card-title>
          <v-card-title v-else class="headline light-green accent-4" primary-title>Enter Codes</v-card-title>

          <v-card-text>
            <v-form class="px-3" id="login-form">
              <v-text-field label="Tag Code" v-model="site_code"></v-text-field>
              <v-text-field label="Tag Password" v-model="id_number"></v-text-field>
              <v-btn @click="addregcode">Enter</v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="red--text" id="outtxt">{{output }}</div>
            <v-spacer></v-spacer>
          </v-card-actions>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancel()">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
-->

<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn color="blue-grey lighten-3" class="pa-5 ma-5" v-on="on">Go Live</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline green lighten-2 justify-center v-card__title"
          primary-title
        >Enter code to create site</v-card-title>

        <v-container>
          <v-card-text>
            After purchasing your site, you will be sent a tag pack, including Tag Code and Tag Password.
            Enter these here, so your tag will point directly to your own site.
            (Alternatively, once your Tag Pack arrives, tap the tag to jump directly to this screen.)
          </v-card-text>
        </v-container>

        <v-card-text>
          <v-form class="px-3" align="center" id="signup-form">
            <div class="text1">Tag Code</div>
            <input v-model="site_code" type="text" class="input1" />
            <div class="text1">Tag Password here</div>
            <input v-model="id_number" @click="giveWarning" type="text" class="input1 mb-5" />

            <v-layout justify-center="true" mt-5></v-layout>
            <v-flex>
              <v-row justify="space-around">
                <v-btn class="mt-5" text color="blue" @click="dialog = false">Cancel</v-btn>
                <v-btn v-on:click="addregcode" class="mt-5">Enter</v-btn>
              </v-row>
            </v-flex>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div color="primary" text id="outtxt" class="red--text">{{output}}</div>
          <v-spacer></v-spacer>
        </v-card-actions>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { firebaseapp } from "@/db";
import { getAuth } from 'firebase/auth'
export default {
  data() {
    return {
      id_number: "",
      site_code: "",
      show_warning: true,
      output: "",
      email: String,
      dialog: this.propShowDialog,
    };
  },

  computed: {
    getUnusedCode() {
      return this.$store.getters.getUnusedCode;
    },
  },
  props: {
    propShowDialog: Boolean,
  },
  methods: {
    async giveWarning() {
      if (this.show_warning == true) {
        await alert("Please note, entering code will bind tag to this account");
        this.show_warning = false;
      }
    },
    cancel() {
      this.dialog = false;
      this.$store.commit("setUnusedCode", null);
      this.site_code = "";
      this.id_number = "";
    },
    getcodeagain() {
      this.site_code = this.getUnusedCode;
    },
    addregcode() {
      if (this.site_code == null) {
        this.site_code = "";
      }
      var scode = this.site_code.toString();
      var user = this.$store.getters.getUser;

      this.email = user.email;
      //   var uid = user.uid;

      console.log("addreg scode = ", scode);
      //  console.log("addreg email = ", this.email);
      //  console.log("addreg uid   = ", uid);
      // document.getElementById("outtxt").className = "blue--text";
      scode = scode.trim();
      if (scode != "") {
        this.checkCode(scode);
      } else {
        this.setOutText("Please enter code");
      }
    },
    checkCode(scode) {
      var scRef = firebaseapp
        .firestore()
        .collection("sc")
        .where("scode", "==", scode);

      console.log("AddSiteDialog 70 scode == ", scode);
      console.log("AddSiteDialog 70 scRef == ", scRef);

      if (scode == "") {
        this.setOutText("Enter Tag Code");
        return;
      }

      if (this.id_number == "") {
        this.setOutText("Enter Confirmation Code");
        return;
      }

      scRef.get().then((res) => {
        console.log("checkCode docs length = ", res.docs.length);
        if (res.docs.length > 0) {
          if (!res.docs[0].data().used) {
            //       var id = res.docs[0].id;
            //        this.markedUsed(scode, id);
            var id = res.docs[0].data().c ? res.docs[0].data().c : "-1";
            // console.log("code already used id === ", id);
            if (id == this.id_number) {
              this.enterCode(scode);
            } else {
              console.log("wrong id code");
              this.setOutText("Confirmation code does not match");
              this.$store.commit("setUnusedCode", null);
              this.site_code = "";
              this.id_number = "";
            }
          } else {
            console.log("code already used");
            this.setOutText("That code is already used");
          }
        } else {
          this.setOutText("Unrecognised code");
        }
      });
    },
    markedUsed(scode) {
      console.log("AddSiteDialog marked used", scode);

      //var usedby = firebaseapp.auth().currentUser.uid;
      const auth = getAuth(firebaseapp)
      const usedby = auth.currentUser.uid

      firebaseapp
        .firestore()
        .collection("sc")
        .where("scode", "==", scode)
        .get()
        .then((res) => {
          //  console.log(res.docs[0].id);

          var id = res.docs[0].id;

          firebaseapp
            .firestore()
            .collection("sc")
            .doc(id)
            .set({ used: usedby }, { merge: true })
            .then(() => {
              console.log("AddSiteDialog ", scode, " marked used");
              this.copyPreview(scode);
              var msg = "Thank you.";
              setTimeout(() => {
                this.output = msg;
                this.dialog = false;
              }, 1000);
            });
        });
    },
    copyPreview(sitecode) {
      //var uuid = firebaseapp.auth().currentUser.uid;
      const auth = getAuth(firebaseapp)
      const uuid = auth.currentUser.uid

      firebaseapp
        .firestore()
        .collection("u/" + uuid + "/s/")
        .doc("preview")
        .get()
        .then((doc) => {
          if (doc.exists && doc.data() != null && doc.data().blocks != null) {
            console.log("preview = ", doc);

            firebaseapp
              .firestore()
              .collection("u/" + uuid + "/s/")
              .doc(sitecode)
              .set(doc.data())
              .then(() => {
                console.log("delete preview here");
                firebaseapp
                  .firestore()
                  .collection("u/" + uuid + "/s/")
                  .doc("preview")
                  .delete();
                /*
  state.name = obj.name;
        state.date = obj.date;
        state.intro = obj.intro;
        state.currentSiteId = obj.scode;
*/

                var n = doc.data().name ? doc.data().name : "";
                var d = doc.data().date ? doc.data().date : "";
                var i = doc.data().intro ? doc.data().intro : "";
                var c = doc.data().allowComments
                  ? doc.data().allowComments
                  : false;

                var obj = {
                  date: d,
                  name: n,
                  intro: i,
                  scode: sitecode,
                  allowComments: c,
                };
                this.$store.commit("updateNameAndDate", obj);
                //this.$store.commit("saveBlocksToDB", obj);

                this.$store.commit("clearData");
              });
          }
        });
    },
    enterCode(sitecode) {
    //  var uid = firebaseapp.auth().currentUser.uid;
   //   const auth = getAuth()
      //const uid = auth.currentUser.uid
      const auth = getAuth(firebaseapp)
      const uid = auth.currentUser.uid

      var uRef = firebaseapp.firestore().collection("u").doc(uid);

      console.log("AddSiteDialog 202  uid == ", uid);

      uRef.get().then((docsnapshot) => {
        if (docsnapshot.exists) {
          var allsitecodes = this.$store.getters.getSiteCodes;

          if (!allsitecodes) {
            allsitecodes = [];
          }

          this.$store.commit("setUnusedCode", null);

          var sc = {
            scode: sitecode,
            name: "",
          };
          allsitecodes.push(sc);
          var obj = {
            sitecodes: allsitecodes,
          };
          console.log("uid ", uid, " exists ");
          uRef
            .set(obj, { merge: true })
            .then(() => {
              console.log("code added : ", sitecode);
              this.$store.commit("addAllSiteCodes", obj);
              this.markedUsed(sitecode);
              this.$emit("shownavbar", true);
              this.$router.push("/home");
            })
            .catch((error) => {
              console.log("AddSiteDialog error ", error);
            });
        } else {
          console.log("uid ", uid, " does not exist ");
        }
      });
    },
    setOutText(txt) {
      var msg = "";
      this.output = txt;
      setTimeout(() => {
        this.output = msg;
      }, 3000);
    },
  },
  mounted() {
    console.log("AddSiteDialog scprop == ", this.propSCode);
    this.site_code = this.getUnusedCode;
  },
};
</script>

<style scoped>
.flex1 {
  display: flex;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.colRed {
  color: #f33;
}
.text1 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  /* background-color: burlywood; */
}
.input1 {
  margin: auto;
  font-size: 1.4rem;
  border-style: solid;
  border-color: rgb(176, 187, 177);
  width: 100%;
  padding: 0.8rem;
}

.btnEnter {
  text-align: right;
}
</style>
