<template>
  <div class="view">
    <div v-if="this.vblocks.length <= 0">No site available on view</div>

    <div v-if="this.vblocks.length > 0">
      <h1 class="display-2 text-center mb-2">{{ name }}</h1>
      <!--
      <h1 class="title text-xs-center">{{ date }}</h1>
      -->

      <v-layout>
        <v-flex xs11>
          <p class="intro">{{ intro }}</p>
        </v-flex>
      </v-layout>
      <!--  <v-img class="imgclassSm" v-if="vblock.i===''" :src="require('@/img/rock1.jpg')" /> -->
      <v-container fluid center class="text-xs-center">
        <div v-for="(vblock, index) in this.vblocks" :key="index">
          <h1 class="mt-5">{{ vblock.h }}</h1>
          <picture>
            <v-img class="imgclassLg" v-if="vblock.i != ''" :src="vblock.i" />
          </picture>
          <p class="bbody">{{ vblock.b }}</p>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
//import firebase from "@/db";
export default {
  data() {
    return {
      id: this.$route.params.id,
      vblocks: Array,
      name: String,
      date: String,
      intro: String
    };
  },
  props: {
    menulinks: Array,
    mybuttons: Array
  },
  methods: {
    /*
    showSite(siteid, uuid) {
      console.log("VIEW siteid = ", siteid, "    uuid = ", uuid);

      var collection = "u/" + uuid + "/s/";
      var ref = firebase
        .firestore()
        .collection(collection)
        .doc(siteid);

      ref.get().then(doc => {
        this.vblocks = [];
        if (doc.data()) {
          this.name = doc.data().name;
          this.date = doc.data().date;
          this.intro = doc.data().intro;
          var index = 0;
          doc.data().blocks.forEach(d => {
            console.log("fetching in view: ", d);
            //this.blocks.push(d, { id: d.id });
            this.vblocks[index] = {};
            this.vblocks[index].h = d.h;
            this.vblocks[index].b = d.b;
            this.vblocks[index].i = d.i;

            index++;
          });
          window.history.pushState({ page: 1 }, "title 1", "?page=1");
        }
      });
    },
    */
    getSiteFromDB() {
      this.vblocks = [];
      this.name = "";

      console.log("View getSiteFromDB  ");

      var siteid = this.id;
      //  siteid = "azzzzbc1";

      if (siteid == "") {
        this.$emit("shownavbar", true);
        this.$router.push('/home')
      }

      this.$router.push({ name: "site", params: { sitecode: siteid } });
      /*
            var ref = firebase
              .firestore()
              .collection("sc")
              .where("scode", "==", siteid);
      
            ref
              .get()
              .then(doc => {
                if (!doc.empty) {
                  var uid = doc.docs[0].data().used;
                  console.log("sc uid = ", uid, "   doc = ", doc);
                  if (uid) {
                    this.showSite(siteid, uid);
                  }
                } else {
                  // doc.data() will be undefined in this case
                  console.log("View : No such document!");
                  this.$router.push("/notfound");
                }
              })
              .catch(function(error) {
                console.log("Error getting document:", error);
                this.$router.push("/notfound");
              })
              .then(() => {
                console.log("vblocks.length == ", this.vblocks.length);
              });
      
            console.log("after db get in view this.id: ", this.id);
            */
    }
  },

  beforeMount() {



    this.getSiteFromDB();
  },
  mounted() {
    this.mybuttons.forEach(element => {
      element.show = false;
    });

    // this.getSiteFromDB();
    /*
    this.menulinks.forEach(ml => {
      if (ml.text === "Status") {
        ml.show = false;
      }
      if (ml.text === "About") {
        ml.show = false;
      }
      if (ml.text === "Publish") {
        ml.show = false;
      }
    });
    */
  }
};
</script>

<style scoped>
.intro {
  font-size: 1.5rem;
  text-align-last: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.imgclassSm {
  width: 15%;
  margin: auto;
  margin-bottom: 10px;
}

.imgclassMed {
  width: 45%;
  margin: auto;
  margin-bottom: 10px;
}

.imgclassLg {
  width: 65%;
  margin: auto;
  margin-bottom: 10px;
}

.home {
  color: 222;
  justify-content: center;
  text-align: center;
}

.bbody {
  /* background: #fefefe; */
  width: 80%;
  margin: auto;
}

.bhead {
  margin-top: 20px;
}
</style>



