<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn title="Sign up for free account" color="black white--text font-weight-light" class="buttonMain mt-5" v-on="on">Free Account</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline teal accent-1 justify-center v-card__title"
          primary-title
        >Create Account</v-card-title>

        <v-card-text>
          <v-form class="px-3" align="center" id="signup-form">
            <div class="text1">Email</div>
            <input v-model.trim="myemail" type="text" class="input1" />
            <div class="text1">Password</div>
            <input v-model="mypwd" :type="pwdtype" class="input1" />
            <div>
              <div class="text-xs-center">
                <div class="ma-5"></div>
                <v-btn v-on:click="submit" class="ma-5">submit</v-btn>

                <v-btn class="ma-5" text icon v-on:click="showPass">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div color="primary" text id="outtxt" class="red--text">{{output}}</div>
          <v-spacer></v-spacer>
        </v-card-actions>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { firebaseapp } from "@/db";
import {  getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
export default {
  data() {
    return {
      myemail: "",
      mypwd: "",
      showPasswordBtn: null,
      pwdtype: "password",
      output: "",
      dialog: false,
    };
  },
  props: {
    propBtnText: String,
  },
  methods: {
    submit() {
   
      const auth = getAuth(firebaseapp)

      // signup to firebaseapp
/*      firebaseapp
        .auth()
        .createUserWithEmailAndPassword(this.myemail, this.mypwd) */
        createUserWithEmailAndPassword(auth, this.myemail, this.mypwd)
        .then((cred) => {
          console.log("cred: ", cred.uid);
          console.log("cred: ", cred.email);

          // create new user DB entry here
          this.createNewUser(cred.uid);

          this.$emit("shownavbar", true);
         // if (this.$route.path !== 'home') this.$router.push('home')
          //this.$router.push("home");
        })
        .catch((error) => {
          this.setOutText(error);
        });

      // this.createUser();
    },
    createNewUser(uid) {
      console.log("SignupDialog About to add NEW USER ");

      var timestamp = new Date();
      var obj = {
        email: this.myemail,
        sitecodes: [],
        datecreated: timestamp,
      };

      firebaseapp
        .firestore()
        .collection("u")
        .doc(uid)
        .set(obj)
        .then(() => {
          console.log("SignupDialog NEW USER CREATED ");
        });
    },
    showPass() {
      console.log("show password ");
      if (this.pwdtype === "password") {
        this.pwdtype = "text";
      } else {
        this.pwdtype = "password";
      }
    },
    setOutText(txt) {
      var msg = "";
      this.output = txt;
      setTimeout(() => {
        this.output = msg;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.buttonMain {
  width: 10rem;
  height: 5rem;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.text1 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  /* background-color: burlywood; */
}
.input1 {
  margin: auto;
  font-size: 1.4rem;
  border-style: solid;
  border-color: rgb(169, 243, 179);
  width: 100%;
  padding: 0.8rem;
}

@media (pointer: coarse), (hover: none) {
      [title] {
        position: relative;
        display: inline-flex;
        justify-content: center;
      }
      [title]:focus::after {
        content: attr(title);
        position: absolute;
        top: -150%;
        color: #000;
        background-color: #fff;
        border: 1px solid;
        width: fit-content;
        padding: 3px;
      }
    }
</style>
