<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        color="blue-grey lighten-3"
        v-on="on"
        @click="openDialog"
        class="pa-5 ma-5"
        title="Edit Site"
      >{{ propButtonTitle }}</v-btn>
    </template>

    <div class="mycard">
      <h2 class="headline blue-grey lighten-2 nobreak" dark primary-title>{{ propTitle }}</h2>

      <h3>Name</h3>
      <!-- <v-card-text class="pb-0 mb-0"> -->
      <v-text-field solo label="Enter Name" class="ma-2" v-model="localname" type="text"></v-text-field>

      <h3>Introduction</h3>

      <v-textarea solo label="Introduction" class="ma-2" v-model="intro" type="text"></v-textarea>
      <!-- </v-card-text> -->

      <!-- <v-card-actions align="space-between" > -->
      <v-container grid-list-md text-xs-center>
        <div>
          <v-checkbox color="grey" label="Allow Visitor Messages" v-model="allowComments"></v-checkbox>
          <div class="grp">
            <div class="mx-auto" style="width:87%;">
              <div v-if="hardLink == ''">
                <v-checkbox color="grey" label="Add Charity Link" v-model="displayCharityLink"></v-checkbox>

                <div v-if="displayCharityLink">
                  <div class="dropdown">
                    <select
                      required
                      @change="handleDropdown($event)"
                      class="box mx-5 px-5"
                      id="dropbox1"
                    >
                      <option>Select Charity</option>
                      <option v-for="choice in charities" :key="choice.id">{{ choice.name }}</option>
                    </select>
                    <div>{{ softLink }}</div>
                  </div>
                </div>
              </div>
              <div v-if="displayCharityLink">
                <v-checkbox 
                 class="pa-5 ma-5"
                  color="grey"
                  label="Display Charity Link at Bottom"
                  v-model="displayAtBottom"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </v-container>
      <div class="bottombar ma-5 pa-5">
        <v-btn class="cancel" color="light-grey" @click="dialog = false" default>Cancel</v-btn>

        <v-btn class="continue" @click="updateSite">Continue</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { firebaseapp } from "../db/index";
import { doc, getDoc, getFirestore } from "firebase/firestore";

import format from "date-fns/format";

// https://firebasestorage.googleapis.com/v0/b/esdata-dd385.appspot.com/o/s%2FfFgtmp6sqsdFWIHxkxWXku0EhPJ2%2F241f76aa%2Freaper1.jpg?alt=media&token=ceeaf6b2-0e46-4351-99d0-c74119bce281

export default {
  data() {
    return {
      dialog: "",
      localname: "",

      allowComments: true,

      displayCharityLink: this.propDisplayCharityLink,
      softLink: this.propSoftLink,
      displayAtBottom: this.propDisplayAtBottom,
      hardLink: this.propHardLink,

      output: "",
      menuFrom: false,
      menuTo: false,

      intro: "",

      strDate: "",
      strDateFrom: "",
      strDateTo: "",

      from: null,
      to: null,

      charities: [
        { id: 'mm', name: 'marymount' }
        // {id: 'ao', name: 'anyother'}
      ],
    };
  },
  computed: {
    fromDate() {
      if (this.from) {
        return format(this.from, "Do MMM YYYY");
      }
      return "";
    },
    toDate() {
      if (this.to) {
        return format(this.to, "Do MMM YYYY");
      }
      return "";
    },
  },

  props: {
    propName: String,
    propSCode: String,
    propDate: String,
    propTitle: String,
    propButtonTitle: String,
    propIntro: String,
    propAllowComments: Boolean,

    propDisplayAtBottom: Boolean,
    propDisplayCharityLink: Boolean,
    propSoftLink: String,
    propHardLink: String
  },

  methods: {
    async getHardLink() {

      var scode = this.propSCode;

      const db = getFirestore(firebaseapp);
      const docRef = doc(db, "sc", scode);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().scode == scode) {

        //console.log("scode == ", docSnap.data().scode)
        this.hardLink = docSnap.data().hardlink
        if (!this.hardLink) {
          this.hardLink = ""
        }
        console.log("snap exists hardlink == ", this.hardLink)
      }
      else {
        console.log("snap does not exist")
      }
    },
    handleDropdown(event) {
      const selected = event.target.options[event.target.options.selectedIndex].text

      const charity = this.charities.filter(element => element.name === selected)

      if (charity.length === 0) {
        console.log('selected   id = ', 'none selected')

        this.charityLink = ""

        const obj = {
          name: "",
          scode: this.propSCode,
          displaycharitylink: false,
          softlink: "",
          displayatbottom: false,
        }
        //     this.$store.commit( 'addCharity', obj)
      }
      else if (charity.length === 1) {

        this.softLink = charity[0].name


        console.log('selected   id = ', charity[0].id)
        console.log('this.softLink = ', this.softLink)
        //console.log('      charity = ',  charity[0])



        const obj = {
          name: charity[0].name,
          scode: this.propSCode,
          displaycharitylink: this.displayCharityLink,
          softlink: this.softLink,
          displayatbottom: this.displayAtBottom
        }

        //       this.$store.commit( 'addCharity', obj)

      }
    },
    async openDialog() {
      console.log("OPEN DIALOG  ");

      // console.log("DialogsSettings propDate = ", this.propDate);
      console.log("DialogSettings scode == ", this.propSCode);
      this.$store.commit("loadBlocksFromDB", this.propSCode);

      if (!this.hardLink) {
        await this.getHardLink()
      }

      if (this.propSCode == "preview") {
        var uuid = this.$store.getters.getUid;
        await firebaseapp
          .firestore()
          .collection("u/" + uuid + "/s/")
          .doc("preview")
          .get()
          .then((doc) => {
            if (doc.exists && doc.data() != null && doc.data().blocks != null) {
              console.log("store getPreviewParts name = ", doc.data().name);
              this.localname = doc.data().name ? doc.data().name : "";
              this.strDate = doc.data().date ? doc.data().date : "";
              this.intro = doc.data().intro ? doc.data().intro : "";

              this.setDateStrings();
            }
          });

        // result.date = "9th Aug 2019 to 23rd Aug 2019";
        // this.localname = result.name;
        // this.strDate = result.date;
        // this.intro = result.intro;
      } else {
        this.from = "";
        this.to = "";

        this.localname = this.propName;
        this.strDate = this.propDate;

        this.intro = this.propIntro;

        if (this.propAllowComments) {
          this.allowComments = this.propAllowComments;
        } else {
          this.allowComments = false;
        }

        this.setDateStrings();
        // setTimeout(() => (this.localname = "a result"), 3000); // resolve
      }
      // this.intro = "result";
    },
    clearFrom() {
      console.log("clear from  ");
      this.strDateFrom = "";
      this.from = "";
    },
    clearTo() {
      this.strDateTo = "";
      this.to = "";
    },
    setDateStrings() {
      if (!this.strDate) {
        this.strDate = "";
        return
      }
      var strs = this.strDate.split(" to ");

      console.log("strDate = ", this.strDate);
      console.log("strs = ", strs);
      if (strs[0]) {
        this.strDateFrom = strs[0];
      }
      if (strs[1]) {
        this.strDateTo = strs[1];
      }
    },
    clearDate() {
      this.to = "";
      this.from = "";
      //    console.log("this.to = ", this.to);
      this.setStrDate();
    },

    setFromDate() {
      this.menuFrom = false;
      this.setStrDate();
      // console.log("setFromDate = ", this.fromDate);
    },
    setToDate() {
      this.menuTo = false;
      this.setStrDate();
      //    console.log("setToDate = ", this.toDate);
    },
    setStrDate() {
      this.strDate = "";

      this.strDateFrom = this.fromDate;
      this.strDateTo = this.toDate;

      var f = this.strDateFrom;
      var t = this.strDateTo;

      if (f != "" && t != "") {
        this.strDate = f + " to " + t;
      } else if (f != "") {
        this.strDate = f;
      } else {
        this.strDate = t;
      }
    },
    updateSite() {
      //     console.log("addName");

      // this.setOutText("Thank You");
      console.log("DialogSettings 204 this.from = ", this.from);
      console.log("DialogSettings 205 this.strFrom = ", this.strFrom);

      this.setStrDate();

      //  console.log("DialogSettings 204 this.propSCode = ", this.propSCode);
      //  console.log("DialogSettings 205 this.localname = ", this.localname);

      //   this.localname = "";

      //   this.$store.commit("loadBlocksFromDB", this.propSCode);

      if (!this.localname) {
        this.localname = "";
      }
      if (!this.strDate) {
        this.strDate = "";
      }

      var scode = this.propSCode;
      if (!scode) {
        scode = "";
      }
      if (!this.intro) {
        this.intro = "";
      }
      if (!this.allowComments) {
        this.allowComments = false;
      }

      var obj = {
        name: this.localname,
        date: this.strDate,
        scode: scode,
        intro: this.intro,
        allowComments: this.allowComments,
        displaycharitylink: this.displayCharityLink,
        displayatbottom: this.displayAtBottom,
        softlink: this.softLink,
        hardlink: this.hardLink
      };

      // this.$store.commit("setDirtyFlag");

      this.$store.commit("updateNameAndDate", obj);

      this.$router.push("/editor/");
    },
    setOutText(txt) {
      var msg = "";
      this.output = txt;
      setTimeout(() => {
        this.output = msg;
        this.dialog = false;
      }, 3000);
    },
  },
  mounted() {



  },
  updated() {
    var dropbox = document.getElementById("dropbox1");

    console.log("dropbox ELEMENT === ", dropbox)
  },
  watch: {
    dialog(visible) {
      if (visible) {
        console.log("dialog visible here this.softLink = ", this.softLink)

        var dropbox = document.getElementById("dropbox1");
        console.log("dropbox element === ", dropbox)

        if (!this.displayAtBottom) {
          this.displayAtBottom = false;
        }
        if (!this.displayCharityLink) {
          this.displayCharityLink = false;
        }
        if (!this.softLink) {
          this.softLink = "";
        }

        // if(!this.hardLink) {
        //   await this.$store.commit("updateNameAndDate", obj);
        // }

        console.log("this.softLink = ", this.softLink)
      } else {
        console.log("dialog hidden here")
      }
    }
  }
};
</script>

/<style  scoped>
h2 {
  padding: 15px;
  font-weight: bold;
  /* border-radius: 10px; */
}

h3 {
  margin-left: 20px;
  margin-top: 5px;
  font-weight: normal;
}

.grp {
  /* background: AliceBlue; */

  border-style: groove;
  border-color: aliceblue;
}
.showlinks {
  /* background-color: rosybrown; */
  display: flex;
  margin: auto;
}
.dropdown {
  display: flex;
}
.continue {
  margin-left: 20%;
}
.cancel {
  margin-right: 20%;
}
.bottombar {
  display: flex;
  justify-content: space-around;
}

.box {
  width: 220px;
  height: 30px;
  border: 1px solid #999;
  font-size: 18px;
  color: #555555;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 2px 2px #ccc;
}
.mycard {
  background-color: #fefeff;
  border-radius: 10px;

  /* width: 100%;  */
}
.nobreak {
  word-break: normal; /* maybe !important  */
}

.block1 {
  width: 50%;
  background-color: #ffeeff;
}
.block2 {
  width: 50%;
  background-color: #11ccff;
}

.canc {
  background-color: #cecece;
  width: 50%;
}

.cbox {
  margin: 60%;
  width: 50%;
}

.align1 {
  margin: auto;
}
</style>
