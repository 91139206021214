import { render, staticRenderFns } from "./ViewNormal.vue?vue&type=template&id=13fbcacc&scoped=true&"
import script from "./ViewNormal.vue?vue&type=script&lang=js&"
export * from "./ViewNormal.vue?vue&type=script&lang=js&"
import style0 from "./ViewNormal.vue?vue&type=style&index=0&id=13fbcacc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13fbcacc",
  null
  
)

export default component.exports