<template>
    <DisplayPage :uuid="uuid" :sitecode="sitecode" />
</template>

<script>
import DisplayPage from '@/views/Display/DisplayPage.vue'

export default {
    name: 'Sample',
    components: { DisplayPage },
    data() {
        return {
            uuid: 'fFgtmp6sqsdFWIHxkxWXku0EhPJ2',
            sitecode: 'wKHpyjijMMt7bt7afv4D'
        }
    },
    mounted() {
        this.$emit("shownavbar", false);
    }
}
</script>

<style>
</style>