<template>
  <v-container>
    <v-dialog v-model="dialog" width="500">
      <v-layout>
        <div class="text-xs-center">
          <v-card class="width:500px;">
            <v-card-title class="error">Reset Password</v-card-title>
            <v-card-actions>
              <v-form class="px-3">
               

                <div class="text1">Account Email</div>
            <input  v-model="resetEmail" type="text" class="input1" />


                <div class="ma-5">
                  <div>A reset password email will be sent to the email address entered</div>

                  <v-btn v-on:click="reset" style="margin:auto; margin-top:2rem;">Reset Password</v-btn>
                  <v-btn v-on:click="gotoHome()" style="float:right; margin-top:2rem;">Home</v-btn>
                </div>
              </v-form>
            </v-card-actions>
          </v-card>

          <div>{{ output }}</div>
        </div>
      </v-layout>
    </v-dialog>
  </v-container>
</template>

<script>
import  { firebaseapp } from "@/db";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default {
  data() {
    return {
      resetEmail: String,
      output: String,
      dialog: true,
    };
  },
  methods: {
    gotoHome() {
      this.$emit("shownavbar", true);
      this.$router.push("/home");
    },
    reset() {
      console.log("reset");
   //   var auth = firebaseapp.auth();
   const auth = getAuth(firebaseapp)

      // var resetEmail = getEmail();

   //   return auth
   //     .sendPasswordResetEmail(this.resetEmail)
        sendPasswordResetEmail(auth, this.resetEmail)
        .then(() => {
          console.log("email sent");
          this.output = "email sent to " + this.resetEmail;
          this.resetEmail = "";
        })
        .catch((error) => {
          console.log(error);
          this.output = "No user " + this.resetEmail + " found";
          this.resetEmail = "";
        });
    },
  },
  mounted() {
    // this.mybuttons.forEach(element => {
    //   element.show = false;
    // });

    this.resetEmail = "";
    this.output = "";

    // this.menulinks.forEach(ml => {
    //   ml.show = true;
    //   // if (ml.text === "Status") {
    //   //   ml.show = false;
    //   // }
    //   // if (ml.text === "About") {
    //   //   ml.show = false;
    //   // }
    //   // if (ml.text === "Publish") {
    //   //   ml.show = false;
    //   // }
    // });
  },
};
</script>

<style scoped>

.input1 {
  margin: auto;
  font-size: 1rem;
  border-style: solid;
  border-color: rgb(255, 206, 191);
  width: 100%;
  padding: 0.4rem;
}
.text1 {
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
  color: #832727;
  /* background-color: burlywood; */
}

</style>