<template>
  <v-app>
    <v-layout justify-center>
      <v-flex
        :class="{
          'ma-0': $vuetify.breakpoint.smAndDown,
          'ma-5': $vuetify.breakpoint.mdAndUp
        }"
        xs12
        sm10
        md8
      >
        <div v-if="true">
          <span v-if="email == ''">
            <v-container>
              <v-col align="center">
                <!-- // https://www.npmjs.com/package/vue-responsive-video-background-player             -->
                
                <!--- Added to check the github repo -->

                <video-background
                  class="vidbg"
                  :src="require('../assets/vid1b.mp4')"
                  style="max-height: 800px; height: 100vh;"
                  :playbackRate="0.5"
                  overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
                >
                  <v-img
                    src="@/assets/logoseethrough3.png"
                    alt="Empathystone.com"
                    class="titlelogo2 my-5"
                  ></v-img>
                  <br />

                  <div v-if="getUnusedCode">
                    <h1 class="green--text text--darken-1 mb-2 ">Welcome!</h1>

                    <v-btn class="buttonMain" @click="demo()">View Demo Page</v-btn>

                    <p>If not already an account holder</p>
                    <Signin :propBtnText="'Create Account'" class="mb-5" />

                    <p>If you already have an account</p>
                    <Login />
                  </div>
                  <div v-else>
                    <v-btn
                      title="Learn more about app"
                      class="buttonMain vertcenter mb-5 black white--text font-weight-light"
                      @click="demo()"
                    >Learn More ></v-btn>

                    <Signin :propBtnText="'Create Account'" />

                    <Login class="mt-5" />

                    <router-link :to="{ name: 'BuyScreen' }">
                      <v-btn title="Purchase Site Token" class="buy-link buttonMain black white--text font-weight-light">Purchase</v-btn>
                    </router-link>
                  </div>
                </video-background>
              </v-col>
            </v-container>
          </span>

          <div v-if="email != ''">
            <v-flex class="text-center">
              <img src="@/img/tag7b.png" alt="Empathystone.com" class="titlelogo1 ma-1" />
            </v-flex>

            <v-container
              v-for="(site, i) in getSiteCodes"
              :key="i"
              class="acard my-1"
              id="i"
              @click="block_selected(i)"
            >
              <v-card>
                <div style="background: AliceBlue" ref="smhead">
                  <span class="titlename mx-1 px-1">{{ site.name }}</span>
                </div>

                <v-card-title style="position: relative">
                  <div class="btncontain">
                    <div v-if="showme[i]">
                      <div class="btns goright">
                        <v-img contain :src="require('@/img/arrow1.png')" @click="moveup(i)" />
                        <v-img contain :src="require('@/img/arrow2.png')" @click="movedown(i)" />
                      </div>
                    </div>

                    <span
                      v-if="display"
                      class="addresstext ml-5"
                    >Empathystone.com/view/{{ site.scode }}</span>
                  </div>
                </v-card-title>

                <v-card-actions>
                  <v-layout class="text-center">
                    <v-flex>
                      <v-row>
                        <v-col>
                          <DialogSettings
                            :propName="site.name"
                            :propSCode="site.scode"
                            :propDate="site.date"
                            :propTitle="'Settings'"
                            :propButtonTitle="'Edit'"
                            :propIntro="site.intro"
                            :propAllowComments="site.allowComments"
                            :propDisplayAtBottom="site.displayatbottom"
                            :propDisplayCharityLink="site.displaycharitylink"
                            :propSoftLink="site.softlink"
                            :propHardLink="site.hardlink"
                          />
                        </v-col>
                        <v-col>
                          <v-btn
                            color="blue-grey lighten-3"
                            @click="gotoMessages(site.scode, site.name)"
                            title="Read Messages"
                            class="pa-5 ma-5"
                          >Messages</v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            class="pa-5 ma-5"
                            title="View Site"
                            color="blue-grey lighten-3"
                            @click="viewSite(site.scode)"
                          >View</v-btn>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-card-actions>
                <br />
              </v-card>
            </v-container>
          </div>
          <!--    
 <v-btn @click="testaddcode()">Add</v-btn>
 <v-btn @click="testsubcode()">Sub</v-btn>
          -->

          <div v-if="email != ''">
            <v-container>
              <v-card>
                <v-card-title
                  primary-title
                  class="blue-grey lighten-3 grey--text-darken-5 mb-5"
                >Create Site</v-card-title>
                <!--
                 <div>
                    <h3 class="headline ma-2 " v-if="getUnusedCode"  >New Site Code Available </h3>
                    <h3 v-else class="headline ma-2">Enter Code</h3>
                  </div>
                -->
                <v-layout class="text-center">
                  <v-flex>
                    <v-row>
                      <v-col>
                        <DialogSettings
                          :propSCode="'preview'"
                          :propDate="''"
                          :propTitle="'Preview'"
                          :propButtonTitle="'Edit'"
                          :propIntro="''"
                        />
                      </v-col>

                      <v-col>
                        <PurchaseDialog />
                      </v-col>

                      <v-col>
                        <AddSiteDialog
                          :selectedindex="selectedindex"
                          :propShowDialog="getUnusedCode != null"
                        />
                      </v-col>
                    </v-row>
                  </v-flex>
                </v-layout>
                <v-card-actions class="text-center mt-5">
                  <v-container>
                    <v-expansion-panels
                      :accordion="true"
                      :popout="true"
                      :inset="true"
                      :multiple="true"
                      :focusable="false"
                      :disabled="false"
                      :readonly="false"
                    >
                      <v-expansion-panel class="grey lighten-4 grey--text-darken-5">
                        <v-expansion-panel-header>Options</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row justify="center" class="white ma-0 pa-0">
                            <v-checkbox label="View Address" v-model="display"></v-checkbox>
                          </v-row>

                          <v-row justify="center" class="white pa-0 ma-0">
                            <v-btn
                              class="afooter elevation-5 blue-grey lighten-4 my-3"
                              @click="gotoHelp()"
                            >Help</v-btn>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-container>
          </div>
        </div>

        <h1 v-else>COMING SOON</h1>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
/*
  These ones should be tidier:
  https://empathystone.com/view/05d0f552     (sample)
  https://empathystone.com/view/327821db   (partners)
  https://empathystone.com/view/b5443070  (terms)
*/
// eir code T23Y5NX
// virgin media 72237487
//import firebase from "@/fb";
//import firebase from "./firebaseInit";
import Signin from "@/views/SignupDialog.vue";
import Login from "@/views/LoginDialog.vue";
import AddSiteDialog from "@/views/AddSiteDialog.vue";
//import SettingsDialog from "@/views/SettingsDialog.vue";
//import PreviewDialog from "@/views/PreviewDialog.vue";
import DialogSettings from "@/views/DialogSettings.vue";
import PurchaseDialog from "@/views/PurchaseDialog.vue";


export default {
  props: {
    siteIds: Array,
    email: String,
    menulinks: Array,
    mybuttons: Array,
  },
  data() {
    return {
      sidepanel: false,

      dialog: false,
      //  notifications: false,
      sound: true,
      //  widgets: false,
      show: [],
      showme: [],
      selectedindex: 0,
      display: false
    };
  },
  computed: {
    getSiteCodes() {
      return this.$store.getters.getSiteCodes;
    },
    getUnusedCode() {
      return this.$store.getters.getUnusedCode;
    },
  },
  components: {
    Login,
    Signin,
    AddSiteDialog,
    //   SettingsDialog,
    //   PreviewDialog
    PurchaseDialog,
    DialogSettings,
  },

  methods: {
    
 
    gotoMessages(scode, name) {
      console.log("SCODE and name === ", scode, name);

      if (!name) {
        name = "";
      }

      if (scode && scode != "") {
        this.$router.push({
          name: "comments",
          params: { siteid: scode, thisname: name },
        });
      }
    },
    moveup(index) {
      //  console.log("moveup");
      this.$store.commit("moveNameUp", index);
    },
    movedown(index) {
      //  console.log("movedown");
      this.$store.commit("moveNameDown", index);
    },
    showstuff(show) {
      console.log("SHOWSTUFF  show ===", show);
    },
    dostuff() {
      this.dialog = true;
    },
    testaddcode() {
      var siteid = "00db3404";
      this.$store.commit("setUnusedCode", siteid);
    },
    testsubcode() {
      this.$store.commit("setUnusedCode", null);
    },
    block_selected(index) {
      console.log("blockselected index = ", index, "refs = ", this.$refs);

      // if(!(index && index >= 0)){
      //   return;
      // }

      this.selectedindex = index;

      var els = this.$refs.smhead;

      if (els != null && els.length > 0) {
        els.forEach((el) => {
          el.style.backgroundColor = "AliceBlue";
        });
        els[index].style.backgroundColor = "Lavender";
      }

      for (let i = 0; i < this.showme.length; i++) {
        this.showme[i] = false;
      }
      this.showme[index] = true;

      //     this.flipshow(index);
      this.$store.commit("updateNames");

      return false;
    },
    testSite() {
      console.log("TEST SITE");
    },
    flipshow(index) {
      var butt = this.show[index];
      for (var i = 0; i < this.show.length; i++) {
        this.$set(this.show, i, false);
      }
      this.$set(this.show, index, !butt);
    },
    demo() {
      this.$router.push("/about");
    },
    viewSite(sitereg) {
      console.log("viewSite sitereg == ", "/view/" + sitereg);
      this.$router.push("/view/" + sitereg);
    },
    gotoHelp() {
      this.$emit("shownavbar", true);
      this.$router.push("/helpscreen");
    },
    editSite(sitereg) {
      console.log("editSite sitereg == ", sitereg);

      if (sitereg === "preview") {
        this.preview();
      } else {
        this.$store.commit("loadBlocksFromDB", sitereg);
        // this.$store.commit("loadBlocksFromDB", sitereg);
        //   console.log("edit site going to now");

        this.$router.push("/editor/");
      }
    },
    async preview() {
      console.log("in preview");

      // // create a new promise inside of the async function
      // let promise = new Promise((resolve, reject) => {
      //   setTimeout(() => resolve("a result"), 1000); // resolve
      // });

      // wait for the promise to resolve
      //  let result = await promise;

      // console log the result (true)
      //   console.log(result);
    },
    settings(index) {
      console.log("settings index = ", index);
    },
    setLinks() {
      this.mybuttons.forEach((element) => {
        element.show = false;
      });

      /*
      this.menulinks.forEach(ml => {
        ml.show = true;

        if (ml.text == "Home" && this.email == "") {
          ml.show = false;
        }
        if (ml.text == "Publish" && this.email == "") {
          ml.show = false;
        }
        if (ml.text == "Status" && this.email == "") {
          ml.show = false;
        }
      });
      */
    }
  },

  mounted() {
    this.setLinks();
    this.$store.commit("setShowHelpButton", { b: false });

 
  },
  beforeMount() {
    this.$emit("shownavbar", true);
  }
};
</script>

<style scoped>
p {
  /* background-color: burlywood; */
  margin-bottom: 0%;
  padding-bottom: 0%;
}
a {
  text-decoration: none;
}

.vertcenter {
  margin-top: 5%;
}
.vidbg {
  /* opacity: 50%; */
  border-radius: 2%;
  width: 100%;
}
.goright {
  /* background: #f54767; */
  float: right;
}
.buy-link {
  margin-top: 20px;
  /* color:#aaaaaa; */
  color: rgb(125, 129, 61);
}

.titlename {
  margin-left: auto !important;
  font-size: 2rem;
}
.btns {
  /*  background-color: rgb(240, 241, 253); */
  height: 60px;
  max-width: 40px;
  margin: auto;
  opacity: 0.2;
}

.btncontain {
  /*background-color: rgb(51, 180, 19); */
  width: 100%;
}

.mycon {
  height: calc(100vh - 148px);
  background-color: burlywood;
}

.afooter {
  min-width: 20%;

  margin-top: 5%;
  padding: 1em;
  border-radius: 10px;
}

/*
.home {
 
  justify-content: center;
  text-align: center;
}
*/
.acard {
  /* margin-bottom: 10px; */
  width: 100%;
}

.bbody {
  background: #fef;
}

h3 {
  /* background: rgb(253, 253, 198); */
  color: rgb(51, 180, 19);
}

.bhead {
  background: #eff;
  margin-top: 20px;
}

.titlelogo1 {
  width: 75%;
}
.titlelogo2 {
  width: 100%;
  /* opacity: 90%; 
   z-index: 1; */
}
/*
.picbanner{
   position: relative;
  top: 0;
  left: -10%; 
  width:100%;
}

.titlePic {
  
  position: relative;
  top: 0;
  left: 0;
  width:40%
}
.image1{
   position: absolute;
  top: 10%;
  left: 80%;
  width:12%; 
}
*/
/*
.image2{
   position: absolute;
  top: 4px;
  left: 10%;
 
  width:9%; 
 
}
*/

.buttonMain {
  width: 10rem;
  height: 5rem;
}

.addresstext {
  font-size: 60%;
  color: #aaaaaa;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

@media (pointer: coarse), (hover: none) {
  [title] {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }
  [title]:focus::after {
    content: attr(title);
    position: absolute;
    top: -150%;
    color: #000;
    background-color: #fff;
    border: 1px solid;
    width: fit-content;
    padding: 3px;
  }
}
</style>

