<template>
  <div class="home">
    <div  class="ma-5 pa-5">
      <h1 class="ma-5">Thank You</h1>
        <p>Your order will be processed and the tag will be sent to you as soon as possible.</p>
     
    </div>
<br>
<br>
    <v-layout class="ma-5 pa-5">
            <v-row   justify="center">
                <v-btn class="afooter blue-grey lighten-3 " @click="gotoHome()">Home</v-btn>
            </v-row>
    </v-layout>


  </div>
</template>

<script>


export default {
  data() {
    return { 
        uid: "" 
    };
  },
  methods: {
     gotoHome(){
      this.$emit("shownavbar", true);
      this.$router.push('/home');
    },
  },

  mounted() {

   
  }
};
</script>

<style scoped>
.home {
  color: 222;
  justify-content: center;
  text-align: center;
}

p {
  width: 80%;
  margin: auto;
}

</style>

