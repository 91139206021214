<template>
<div>
  <h1 class="mt-5 pt-5">{{ vblock.h }}</h1>

              <div v-if="vblock.s" class="light-blue lighten-5 pa-2 rounded-xl">
                <p>{{ vblock.t }}</p>
                <div>
                  <a href="#" @click="gotoLink(vblock.l)"> {{ vblock.l }} </a>
                </div>
              </div>

              <div v-if="vblock.v && vblock.v.length > 2">
                <v-layout class="justify-center">
                  <v-flex xs12 md9>
                    <div class="resp-container">
                      <iframe
                        id="ytplayer"
                        type="text/html"
                        class="resp-iframe"
                        :src="getSrc(vblock.v)"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </v-flex>
                </v-layout>
              </div>

              <div v-else>
                <v-layout class="justify-center">
                  <v-flex xs12 md9>
                    <v-img v-if="vblock.i != ''" :src="vblock.i" />
                  </v-flex>
                </v-layout>
              </div>
              <p class="mt-1 mb-5">{{ vblock.b }}</p>
</div>
</template>

<script>
export default {
    name: 'DisplayBlock',
    props: {  vblock : Object  },
    methods: {
      gotoLink(link) {
        console.log("gotoLink ")
      if (link || link.length > 5) {
        //window.location.replace( 'http://www.google.com' );
        window.open("http://" + link, "_blank");
      }
    },
         getSrc(vid) {
      return (
        "https://www.youtube.com/embed/" +
        vid +
        "?autoplay=0&origin=http://empathystone.com"
      );
    },
    }
}
</script>

<style scoped>


</style>