<template>
    <div >
      
      <v-content>
    <!--- pick-up screen height so we can center vertically -->
    <v-container fill-height>
        <!--- vertical and horizontal alignment -->
        <v-layout align-center justify-center>
            <v-flex xs6 >
            <!--- v-flex is centered now, but we need to center button inside v-flex -->
             <v-row justify="center" >
                <span style="text-align:center;">Click below to receive a tag that will bring visitors to a page of your design</span>
             </v-row>
             <v-row justify="center" class="ma-5">
                <PurchaseDialog   />
             </v-row>
              <v-row justify="center" class="ma-5">
                <img src="../assets/es_01.jpg" style=" height:70vh;" > 
             </v-row>
             
            </v-flex>
        </v-layout>
    </v-container>
</v-content>



    </div>
</template>

<script>
import PurchaseDialog from '../views/PurchaseDialog.vue'

export default {
    components: { PurchaseDialog },
    data(){
        return {
            dialog: true
        }
    }
}
</script>

<style scoped>



</style>>


