 <template>
  <v-container style="overflow: hidden" >
     <v-flex 
      :class="{'ma-0': $vuetify.breakpoint.smAndDown, 
                'ma-5': $vuetify.breakpoint.mdAndUp}"
      xs12 sm10  mx-auto>
      <div class="options">
        <v-layout class="justify-space-around">
          <!--         <span >    
              Hide Header <input type="checkbox" :checked="hideheader"
              @click="saveHideHeader($event)"/> 
          </span>    
          -->
          <span>
            Hide Name
            <input
              type="checkbox"
              :checked="hidename"
              @click="saveHideName($event)"
            />
          </span>
          <span>
            Hide Intro
            <input
              type="checkbox"
              :checked="hideintro"
              @click="saveHideIntro($event)"
            />
          </span>

          <span>
            Redirect Page
            <input
              type="checkbox"
              :checked="redirectPage"
              @click="saveRedirectPage($event)"
            />
          </span>
        </v-layout>

        <div v-if="redirectPage" class="redirect">
          Redirect to URL
          <input
            type="text"
            class="redirectInput"
            :value="localURL"
            placeholder="Enter URL Here"
            v-on:blur="saveRedirectURL($event)"
          />
        </div>
      </div>

      <span class="ma-1"></span>
      <!--
       <div v-if="hideheader == false">
        <button class="aheader blue-grey lighten-4 mt-3" >EmpathyStone.com</button>
    </div>
     <div v-else>
        <button class="aheader grey lighten-5  mt-3" >EmpathyStone.com</button>
    </div>
    -->
      <!--
      <div v-if="false">
        <v-layout row wrap>
          <v-flex xs12 justify="space-around">
            <v-row>
              <v-img :src="require('@/img/imgselect1.png')" contain height="50" />

              <p>Insert Image File</p>
            </v-row>

            <v-row>
              <v-img :src="require('@/img/video1.png')" contain height="50" />
              <p>Insert Video Link</p>
            </v-row>
          </v-flex>
        </v-layout>
      </div>
-->
      <div>
        <div v-if="hidename">
          <h1 class="display-2 text-center mb-1 mt-1 grey--text">
            {{ getName }}
          </h1>
        </div>
        <div v-else>
          <h1 class="display-2 text-center mb-1 mt-1">{{ getName }}</h1>
        </div>
        <!--      <h1 class="title text-xs-center">{{ getDate }}</h1> -->
        <div v-if="hideintro == true" class="introhide">
          {{ getIntro }}
        </div>
        <div v-else class="intro">
          {{ getIntro }}
        </div>
      </div>

      <div class="text-center">
        <v-dialog v-model="selectVideo" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" light-blue lighten-5
              >Enter Youtube video code</v-card-title
            >

            <v-form>
              <v-container>
                <v-col cols="12">
                  <v-text-field
                    solo
                    label="Youtube code"
                    placeholder="youtube"
                    v-model="vidcode"
                  ></v-text-field>
                </v-col>
              </v-container>
            </v-form>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="background-color: #cccccc"
                color="green"
                @click="store_vid_code()"
                >Ok</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div
        v-for="(block, index) in getBlocks"
        :key="index"
        @click="clicked_block(index)"
        :selectedBlock="selectedBlock"
        :id="index"
      >
        <div align="center">
          <div
            class="indigo lighten-5"
            style="width: 100%; border-radius: 10px"
          >
            <div style="width: 60%">
              <v-layout v-if="selectedBlock == index">
                <v-flex xs1 class="mx-4 my-2">
                  <v-img
                    :src="require('@/img/imgselect1.png')"
                    min-height="30px"
                    min-width="30px"
                    onclick="document.getElementById('file-input').click()"
                    title="Insert Image"
                  />

                  <input
                    id="file-input"
                    type="file"
                    class="d-none"
                    v-on:change="readURL($event, index)"
                  />
                </v-flex>
                <v-flex xs1 class="mx-4 my-2">
                  <v-img
                    v-if="selectedBlock == index"
                    :src="require('@/img/video1.png')"
                    min-height="30px"
                    min-width="30px"
                    @click="open_dialog(index)"
                    title="Insert Video File"
                  />
                </v-flex>

                <v-flex v-if="!block.s" xs1 class="mx-4 my-2">
                  <v-img
                    v-if="selectedBlock == index"
                    :src="require('@/img/link1.png')"
                    min-height="30px"
                    min-width="30px"
                    @click="add_link(index)"
                    title="Insert Video File"
                  />
                </v-flex>

                <v-flex v-if="block.s" xs1 class="mx-4 my-2 pink lighten-3">
                  <v-img
                    v-if="selectedBlock == index"
                    :src="require('@/img/link1.png')"
                    min-height="30px"
                    min-width="30px"
                    @click="add_link(index)"
                    title="Insert Video File"
                  />
                </v-flex>

                <v-spacer></v-spacer>
                <!-- UP -->
                <v-flex xs1 offset-4 class="mx-4 my-2">
                  <v-img
                    v-if="index > 0 && selectedBlock == index"
                    :src="require('@/img/arrow1.png')"
                    min-height="30px"
                    min-width="30px"
                    @click="moveup(index)"
                    title="Move block up"
                  />
                </v-flex>

                <!-- DOWN -->
                <v-flex xs1 class="mx-4 my-2">
                  <v-img
                    title="Move block down"
                    v-if="
                      selectedBlock == index && !(index > getBlocks.length - 2)
                    "
                    :src="require('@/img/arrow2.png')"
                    min-height="30px"
                    min-width="30px"
                    @click="movedown(index)"
                  />
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
        
        <v-text-field
        label="header"
        outlined
          single-line
          class="blockheader "
          :value="block.h"
          placeholder="Add Header Text"
          @change="add_head_text($event, index)"
          @click="displaySaveButton()"
        ></v-text-field>
         
        <div v-if="block.s" class="pink lighten-5 ma-1 pt-5 pl-4 pr-4">
          <v-text-field
            label="Text"
            @click="displaySaveButton()"
            :value="block.t"
            placeholder="Place text here"
            @change="add_link_text($event, index)"
            outlined
          >
          </v-text-field>
          <v-text-field
            label="URL"
            :value="block.l"
            @click="displaySaveButton()"
            placeholder="Place link here"
            @change="add_link_url($event, index)"
            outlined
          >
          </v-text-field>
        </div>

        <br />
        <div class="videoblock">
          <div v-if="block.v || block.v.length > 2">
            <div class="parent text-center">
              <div class="resp-container">
                <iframe
                  id="ytplayer"
                  type="text/html"
                  class="resp-iframe"
                  :src="getSrc(block.v)"
                  frameborder="0"
                ></iframe>
              </div>

              <v-img
                v-if="selectedBlock == index"
                :src="require('@/img/removeimage1.png')"
                @click="removeimage(index)"
                class="image2"
              />
            </div>
          </div>
          <div v-else>
            <div class="parent">
              <v-img
                :src="block.i === '' ? require('@/img/tag5.png') : block.i"
                lazy-src="require('@/img/arrow1.png')"
                class="image1"
              />

              <v-img
                v-if="selectedBlock == index && block.i !== ''"
                :src="require('@/img/removeimage1.png')"
                @click="removeimage(index)"
                class="image2"
              />
            </div>
          </div>
        </div>

        <v-textarea
          class="blockbody"
          :value="block.b"
          @change="add_body_text($event, index)"
          label="Main Body Text"
          @click="displaySaveButton()"
          auto-grow
          outlined
        ></v-textarea>
        <div align="center">
          <div
            class="indigo lighten-5 ma-1"
            style="width: 40%; border-radius: 10px"
          >
            <v-layout v-if="selectedBlock == index">
              <v-spacer></v-spacer>
              <v-flex xs2 class="mx-5 my-1">
                <v-img
                  v-if="selectedBlock == index"
                  :src="require('@/img/newblock1.png')"
                  min-height="30"
                  min-width="30"
                  @click="add_block()"
                  title="Insert a new block"
                />
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
          </div>
        </div>
      </div>
    </v-flex>

    <v-layout>
      <v-col align="center">
        <div v-if="getConfirmDialog">
          <v-btn class="error mx-2" @click="publish()">SAVE AND BACK</v-btn>
        </div>
        <div v-else>
          <v-btn class="afooter blue-grey lighten-4" @click="gotoHome()"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
//import { firebaseapp } from "../db";
import { getAuth } from 'firebase/auth'
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage'
import path from "path";
import { firebaseapp } from '../db';

// icon outline brush size 30px
// brush d)_Ink-2_FineLiner

export default {
  props: {
    //  menulinks: Array,
    mybuttons: Array,
    email: String
  },
  data() {
    return {
     
      imageName: "",
      imageFile: "",
      imageUrl: "",
      selectedBlock: "0",
   

      showHelpButton: true,
  
      hideheader: false,
      hidename: false,
      hideintro: false,
      redirectPage: false,
      localURL: "",

      selectVideo: false,
      vidcode: "",
      playerVars: {
        autoplay: 0,
        origin: "https://www.empathystone.com",
      }
    };
  },
  computed: {
    getConfirmDialog() {
      return this.$store.getters.getDirtyFlag;
    },
    getShowEditorHelp() {
      return this.$store.getters.getShowEditorHelp;
    },
    getBlocks() {
      return this.$store.getters.getBlocks;
    },
    getName() {
      return this.$store.getters.getName;
    },
    getDate() {
      return this.$store.getters.getDate;
    },
    getIntro() {
      return this.$store.getters.getIntro;
    },
  },
  methods: {
    saveRedirectURL(ele) {
      console.log("saveRedirectURL ", ele.target.value);
      let rdvalue = ele.target.value;
      rdvalue = rdvalue.trim();
      this.$store.commit("setRedirectURL", rdvalue);
      this.$store.commit("setDirtyFlag");
    },
    saveHideHeader(ele) {
      // console.log("saveheader == ", ele.target.checked);
      this.$store.commit("setHideHeader", ele.target.checked);
      this.$store.commit("setDirtyFlag");
      this.hideheader = ele.target.checked;
    },
    saveHideName(ele) {
      // console.log("savename == ", ele.target.checked);
      this.$store.commit("setHideName", ele.target.checked);
      this.$store.commit("setDirtyFlag");
      this.hidename = ele.target.checked;
    },
    saveHideIntro(ele) {
      // console.log("saveintro == ", ele.target.checked);
      this.$store.commit("setHideIntro", ele.target.checked);
      this.$store.commit("setDirtyFlag");
      this.hideintro = ele.target.checked;
    },
    saveRedirectPage(ele) {
      console.log("redirectPage == ", ele.target.checked);
      this.$store.commit("setRedirectPage", ele.target.checked);
      this.$store.commit("setDirtyFlag");
      this.redirectPage = ele.target.checked;
    },
    publish() {
      //    console.log("publish");
      //  this.saveHideStatus();

      this.$store.commit("saveBlocksToDB");
      this.gotoHome();
    },
    toggleHelpButton() {
      this.$store.commit("toggleShowEditorHealth");
    },
    removeimage(index) {
      console.log("removeimage ", index);

      var sure = confirm(
        "        Are you sure?\nDo you Really want to DELETE this file."
      );
      if (sure) {
        //  this.$store.commit("backupImage", index);
        this.$store.commit("clearImage", index);
        this.$store.commit("setDirtyFlag");
      }
    },
    gotoHome() {
      this.$emit("shownavbar", true);
      this.$router.push("/home");
    },
    async add_block() {
      // this.addblock = true;

      // setTimeout(() => {
      //   this.addblock = false;
      // }, 1000);

      var blocks_length = await this.$store.getters.getBlocksLength;

      //  console.log("ADD_BLOCK blocks.length == ", blocks_length);

      if (blocks_length > 19) {
        console.log("REACHED ADDBLOCK LIMIT");
        return;
      }

      // this.selectedBlock += 1;
      this.$store.commit("insertNewBlock", this.selectedBlock);

      // var offset = window.scrollY + 380;
      // window.scroll({
      //   top: offset,
      //   left: 0,
      //   behavior: "smooth",
      // });
      // this.$store.commit("setDirtyFlag");

      // setTimeout(() => this.clicked_block(this.selectedBlock + 1), 500);
      this.movedown(this.selectedBlock);
    },
    clicked_block(id) {
      console.log("clicked block: ", id);

      this.vidcode = "";
      

      this.selectedBlock = id;
      this.$store.commit("setBlockIndex", { index: id });

      var el = null;
      for (var i = 0; i < 100; i++) {
        el = document.getElementById(i);
        if (el != null) {
          el.style.background = "white";
        }
      }
      el = document.getElementById(id);
      if (el != null) {
        el.style.background = "#f6f6ff";
        el.style.borderRadius = "20px";
      }
    },

    getSrc(vid) {
      return (
        "https://www.youtube.com/embed/" +
        vid +
        "?autoplay=0&origin=http://empathystone.com"
      );
    },

    store_vid_code() {
//      console.log("ADD VIDEO index = ", this.selectedBlock);
//      console.log("ADD VIDEO vidcode 1 = ", this.vidcode);

      var vcode = this.vidcode;

      // fix vidcode we just want code part
      vcode = vcode.substring(vcode.indexOf("=") + 1);

      var vcode2 = vcode.substring(0, vcode.indexOf("&"));

      if (vcode2.length > 1) {
        vcode = vcode2;
      }
      else { // youbu.be website
        vcode = vcode.split("/").pop();
      }

      this.vidcode = vcode;

//      console.log("ADD VIDEO vidcode 2 = ", this.vidcode);

      this.$store.commit("setDirtyFlag");

      this.selectVideo = false;
      var obj = {
        i: this.selectedBlock,
        vidcode: this.vidcode,
      };
      this.$store.commit("setBlockVideoId", obj);
    },
    open_dialog(index) {
      this.selectVideo = true;
      this.selectedBlock = index;
    },
    add_link(index) {
      console.log("add_link index = ", index);
      //    this.showLink = !this.showLink;

      this.$store.commit("toggleAddLink", index);

      //this.selectVideo = true;
      //this.selectedBlock = index;
    },
    movedown(index) {
      this.$store.commit("movedown", index);
      var offset = window.scrollY + 500;
      window.scroll({
        top: offset,
        left: 0,
        behavior: "smooth",
      });
      this.$store.commit("setDirtyFlag");
      setTimeout(() => this.clicked_block(index + 1), 100);
    },
    moveup(index) {
      console.log("moveup");

      this.$store.commit("moveup", index);
      var offset = window.scrollY - 500;
      window.scroll({
        top: offset,
        left: 0,
        behavior: "smooth",
      });
      this.$store.commit("setDirtyFlag");
      setTimeout(() => this.clicked_block(index - 1), 100);
    },
    add_link_text(text, index) {
      // added to test github from mint 20
      //console.log("add link textbox : ", text, "index: ", index);
      this.$store.commit("setLinkText", { text, index });
      this.$store.commit("setDirtyFlag");
    },
    add_link_url(text, index) {
      text = text.replace(/^https?:\/\//, '')
      // added to test github from mint 20
      //console.log("add link url : ", text, "index: ", index);
      this.$store.commit("setLinkUrl", { text, index });
      this.$store.commit("setDirtyFlag");
    },
    displaySaveButton() {
      this.$store.commit("setDirtyFlag");
    },
    add_head_text(text, index) {
      //      console.log("add header: ", text, "index: ", index);
      this.$store.commit("setHeaderText", { text, index });
      this.$store.commit("setDirtyFlag");
    },
    add_body_text(text, index) {
      //      console.log("add body: ", text, "index: ", index);
      this.$store.commit("setBodyText", { text, index });
      this.$store.commit("setDirtyFlag");
    },
    readURL(event, index) {
      // TODO!
      // https://stackoverflow.com/questions/56923047/how-to-degrade-image-quality-when-uploading-to-fire-base

      console.log(" readURL event: ", event);
      this.onFilePicked(event, index);
      //  this.$store.commit("setDirtyFlag");
    },
    onFilePicked(e, index) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile = fr.result;
          /*
          // convert imagefile to webp
          var webp=require('webp-converter');
          var output = null;
          webp.cwebp(this.imageFile,output,"-q 80",function(status,error)
          {
            //if conversion successful status will be '100'
            //if conversion fails status will be '101'
            console.log(status,error);
          });

          if(output != null){
            console.log("WE GOT A CONVERT")
          }
          else{
            console.log("WE HAVE NOT GOT  A CONVERT")
          }
*/
          this.setImagePath(index);
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    setImagePath(index) {
      // save image to firebaseapp storage and set url to block.i
      
 // var picRef = ref(storageRef, storeUrl);
 
   //   var storageRef = firebaseapp.storage().ref();
      const storage = getStorage()
      const storageRef = ref(storage, storeUrl)
    

      var ext = path.extname(this.imageName);
      //    var basename = path.basename(this.imageName, ext);
      var content_Type = "image/" + ext;

     // var uuid = firebaseapp.auth().currentUser.uid;
      const auth = getAuth(firebaseapp)
      const uuid = auth.currentUser.uid

      //   console.log("editor uuid = ", uuid);
      var siteId = this.$store.getters.getCurrentSiteId;
      var storeUrl = "s/" + uuid + "/" + siteId + "/" + this.imageName;
      //      console.log("Editor storeUrl = ", storeUrl);
      //      console.log("Editor index = ", index);
     // var picRef = storageRef.child(storeUrl);
      const picRef = ref(storageRef, storeUrl);

      index = this.selectedBlock;
      uploadString(picRef, this.imageFile, "data_url", {
          contentType: content_Type,
        })
        .then(() => {
          this.storePictureRef(picRef, index);
          this.vidcode = "";
          this.store_vid_code();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    storePictureRef(picRef, index) {
      
        getDownloadURL(picRef)
        .then((url) => {
          this.$store.commit("setImagePath", { url, index });
          //        console.log("Editor index = ", index);
          console.log("Editor url = ", url);
          this.$store.commit("setDirtyFlag");
          //https://firebasestorage.googleapis.com/v0/b/esdata-dd385.appspot.com/o/s%2FfFgtmp6sqsdFWIHxkxWXku0EhPJ2%2F241f76aa%2Ftor1.jpg?alt=media&token=4ececfb8-d114-4e63-abc7-7030a47a977c
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  beforeRouteLeave(to, from, next) {
    console.log("Leaving Editor");
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    //  this.saveHideStatus();

    if (this.$store.getters.getDirtyFlag == true) {
      var sure = confirm("        Are you sure?\nYou have unsaved changes.");
      if (sure) {
        // reload site here and lose changes
        this.$store.commit("clearDirtyFlag");
        next();
      }
      // cancel and stay on editor
      next(false);
    } else {
      next();
    }
  },
  beforeMount() {
    //   this.$store.commit("setBlockIndex", 0);
    this.$store.commit("setShowHelpButton", { b: true });

    //    this.title = this.$store.getters.getTitle;
    //    this.date = this.$store.getters.getDate;
  },
  mounted() {
    // this.$refs.youtube.player.resize()

    this.$store.commit("setBlockIndex", { index: 0 });
    this.clicked_block(0);

    var hheader = this.$store.getters.getHideHeader;
    if (hheader == true) {
      this.hideheader = true;
    }
    var hname = this.$store.getters.getHideName;
    if (hname == true) {
      this.hidename = true;
    }
    var hintro = this.$store.getters.getHideIntro;
    if (hintro == true) {
      this.hideintro = true;
    }

    var lredirectPage = this.$store.getters.getRedirectPage;
    console.log("redirectpage == (true / false) " + lredirectPage);
    if (lredirectPage == true) {
      this.redirectPage = true;
    }

    var lredirectURL = this.$store.getters.getRedirectURL;
    console.log("redirectURL === " + lredirectURL);
    if (lredirectURL) {
      //this.redirectURL = "www.refirese";
      //    console.log("redirectURL === " + lredirectURL);
      this.localURL = lredirectURL;
    }

    this.mybuttons.forEach((element) => {
      element.show = true;
    });

    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
/* .mycontainer {
  overflow:fixed;
} */

div {
  white-space: pre-wrap;
}
.aheader {
  width: 100%;

  margin-bottom: 5%;
  padding: 1em;
  border-radius: 10px;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(209, 209, 209, 0.986);
}


.redirect {
  padding-top: 4px;
}

.redirectInput {
  padding: 1px;
  background-color: #fefefe;
  border-style: solid;
  width: 90%;
}
.options {
  background-color: #ececec;
  padding: 2%;
}

.addbtn {
  border: 1px solid black;
}
.vidtext {
  background-color: #2f2;
  margin-top: 1rem;
}
.videoblock {
  margin: auto;
  width: 80%;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}



.imgclassLg {
  width: 95%;
  margin: auto;
}

.afooter {
  min-width: 20%;

  margin-top: 5%;
  padding: 1em;
  border-radius: 10px;
}

.videoclass {
  margin: auto;
  width: 100%;
}

.intro {
  /* font-size: 1.5rem; 
  color: #2f2; */
  text-align-last: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.introhide {
  /* font-size: 1.5rem; */
  color: #acacac;
  text-align-last: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blockheader {
  text-align-last: center;
  text-align: center;
  /* margin-top: 2%; */
  /* background: #ffedff; */
  font-size: 1.5em;
}

.date {
  font-size: 1.8rem;
  text-align-last: center;
  font-weight: lighter;
}

.blockbody {
  display: inline-block;
  /* border: solid 1px #000; */
  min-height: 20%;
  width: 100%;

  text-align-last: center;
}

.videotitle {
  color: #2f2;
  background-color: #f7f7f7;
  justify-content: center;
  text-align: center;
}

.videoidcode {
  color: #2f2;
  background-color: #f7f7f7;
}

.aheader {
  width: 100%;

  margin-bottom: 5%;
  padding: 1em;
  border-radius: 10px;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  /* border: 1px rgb(238, 255, 0) solid; */
}
.image2 {
  position: absolute;
  top: 0px;
  left: 95%;
  border: 1px rgb(232, 238, 151) solid;
  width: 5%;
}

@media (pointer: coarse), (hover: none) {
  [title] {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }
  [title]:focus::after {
    content: attr(title);
    position: absolute;
    top: 150%;
    color: #000;
    background-color: #fff;
    border: 1px solid;
    width: fit-content;
    padding: 3px;
  }
}
/*
.mynowrap-overflow {
  background-color: #2f2; 
 

  margin: auto;
}
*/
/*
.block1 {
  background-color: #f4f;
  color: #a3a;
}

.fullwidth {
  width: 100%;
}


.blockimg {
  margin: 10px;
}


.hidefile {
  display: hidden;
}
*/
</style>