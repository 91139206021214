<template>
  <v-container style="overflow: hidden" class="view">
  <DisplayHeader :headertext="headertext" />
    <v-flex
      :class="{
        'ma-0': $vuetify.breakpoint.smAndDown,
        'ma-5': $vuetify.breakpoint.mdAndUp,
      }"
      xs12 md10 mx-auto >
    
        <DisplayHeaders :header="header" />

        <v-container fluid center class="text-center">
          <div v-for="(vblock, index) in this.vblocks" :key="index">
            <DisplayBlock :vblock="vblock" />
          </div>
        </v-container>

        <DisplayMessages :comments="comments" />
        <DisplayLeaveMessage v-if="allowComments" :email="email" :sitecode="sitecode" :name="header.name"/>
       
    </v-flex>
     <DisplayFooter :footertext="footertext" />
  </v-container>
</template>

<script>
import { firebaseapp } from "../../db";
import DisplayBlock from "./DisplayBlock.vue";
import DisplayHeaders from "./DisplayHeaders.vue";
import DisplayMessages from "./DisplayMessages.vue";
import DisplayHeader from "./DisplayHeader.vue";
import DisplayFooter from "./DisplayFooter.vue";
import DisplayLeaveMessage from "./DisplayLeaveMessage.vue";

export default {
  name: "DisplayPage",
  components: { 
    DisplayBlock, 
    DisplayHeaders, 
    DisplayMessages, 
    DisplayHeader, 
    DisplayFooter,
    DisplayLeaveMessage
  },
  props: {
    sitecode: {
      default: "",
      type: String
    },
    uuid: {
      default: "",
      type: String
    },
    headertext: {
      default: "Empathystone.com",
      type: String
      },
    footertext: {
      default: "Empathystone.com",
      type: String
    }

  },
  data() {
    return {
      vblocks: [],
      header: {
        name: "",
        date: "",
        intro: "",
        hidename: false,
        hidedate: false,
        hideintro: false,
      },
      allowComments: false,
      email: String,
      comments: [],
      
      /*       name: '',
            date: '',
            intro: '',
            allowComments: false,
            allowVideo: false,
            email: '',
            sitetype: '',
            hideheader: false,
            hidename: false,
            hideintro: false
            */
    };
  },
 
  mounted() {
    console.log("test mounted");

    //   this.showSite("GevqijHBCvTMyWB2jWDBHoP2qZH2", "05d0f552")
   

    this.showSite(this.uuid, this.sitecode);
  },
  methods: {
    showSite(uuid, sitecode) {
      var collection = "u/" + uuid + "/s/";

      console.log("collection = ", collection, "    uuid = ", uuid);

      var ref = firebaseapp.firestore().collection(collection).doc(sitecode);

      ref.get().then((doc) => {
        if (doc.data()) {
          this.vblocks = [];

          this.header.name = doc.data().name;
          this.header.date = doc.data().date;
          this.header.intro = doc.data().intro;
          this.allowComments = doc.data().allowComments
            ? doc.data().allowComments
            : false;
          // this.allowVideo = doc.data().allowVideo
          //   ? doc.data().allowVideo
          //   : false;

              this.email = doc.data().email ? doc.data().email : "";

          //    this.sitetype = "";

          // if (doc.data().sitetype) {
          //   this.sitetype = doc.data().sitetype;
          // }

          this.header.hidename = false;
          this.header.hidedate = false;
          this.header.hideintro = false;

          if (doc.data().hideheader) {
            this.hideheader = doc.data().hideheader;
          }
          if (doc.data().hidename) {
            this.hidename = doc.data().hidename;
          }
          if (doc.data().hideintro) {
            this.hideintro = doc.data().hideintro;
          }

          var index = 0;
          doc.data().blocks.forEach((d) => {
            this.vblocks[index] = {};
            this.vblocks[index].h = d.h;
            this.vblocks[index].b = d.b;
            this.vblocks[index].i = d.i;
            this.vblocks[index].v = d.v;
            this.vblocks[index].s = d.s;
            this.vblocks[index].l = d.l;
            this.vblocks[index].t = d.t;

            index++;
          });
        } else {
          console.log("no data");
        }
      });
      var commentsRef = firebaseapp.firestore().collection("c").doc(sitecode);

      commentsRef.get().then((doc) => {
        if (doc && doc.data()) {
        //  console.log("comment here == ", doc.data());
        if(doc.data().c){

          this.comments.sort((a,b) => (a.t < b.t) ? 1 : -1 )
          

          this.comments = doc.data().c.filter((ele) => {
            if (ele.d == true && ele.a == true) {
              return ele;
            }
          })   
        }
        return null
        }
      })
    },
  },
};
</script>

<style>

div {
  white-space: pre-wrap;
}
a { 
    text-decoration: none;
    width: 100%;
}

.header1 h1{
    padding: 10px;
    margin-top: 0px;
    margin-bottom:5%;
    background-color:#dddddd;
    width: 100%;
    border-radius: 10px;
    text-align:center;
    color: #666666;
}

.footer1 h1{
    padding: 10px;
    margin-top: 5%;
    background-color:#dddddd;
    width: 100%;
    border-radius: 10px;
    text-align:center;
    color: #666666;
}
.floatright {
  float: right;
  margin-right: 2rem;
}

.messheader {
  background-color: #f0f1f1;
}
.messcontainer {
  color: black;
  background-color: #fefefe;
  width: 100%;
}


.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.allcomm {
  background-color: #ecfcfc;
}

.myheader1 {
  margin-left: 5%;
  margin-top: 1rem;
 
}

.comments {
  margin: auto;
  width: 80%;
}


.submitButton {
  margin-left: 70%;
  padding: 2em;
  border-radius: 10px;
}

.intro {
  font-size: 1.5em;
  text-align-last: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.imgclassSm {
  width: 15%;
  margin: auto;
}

.imgclassMed {
  width: 45%;
  margin: auto;
}

.imgclassLg {
  width: 75%;
  margin: auto;
}

.home {
  color: 222;
  justify-content: center;
  text-align: center;
}

.bbody {
  

  width: 80%;

  margin: auto;

  margin-top: 3%;
  margin-bottom: 12%;
}

</style>